import { DropdownMenuItem } from "@/components/ui/dropdown-menu";
import { Icon } from "@/components/ui/icon";
import { useCreateEmailDraftMutation, useOpportunityDetailsQuery } from "../../../generated/graphql";

export const CreateEmail = ({ opportunityId }: { opportunityId: string }) => {
  const [createEmailDraft] = useCreateEmailDraftMutation();
  const { data } = useOpportunityDetailsQuery({ variables: { id: opportunityId }, nextFetchPolicy: "cache-first" });

  const opportunity = data?.opportunity;

  const handleClicked = async () => {
    if (opportunity) {
      await createEmailDraft({
        variables: {
          input: {
            opportunityId,
            to: [opportunity.insured.agent.email],
            cc: ["submissions@quotewell.com"],
            subject: `Update on ${opportunity.insured.name}`,
            text: "",
          },
        },
        onCompleted: (data) => {
          if (data?.createEmailDraft) {
            window.open(data.createEmailDraft, "_blank");
          }
        },
      });
    }
  };

  return (
    <DropdownMenuItem onClick={handleClicked}>
      <Icon icon="mail" className="mr-3" /> Send Email to Agent
    </DropdownMenuItem>
  );
};
