import { STRICT_STATE_OPTIONS } from "@cp/toolkit";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Badge } from "../../components/ui/badge";
import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../components/ui/select";
import {
  CglRuleItem,
  CglRuleOperator,
  ClientDataRuleBooleanOperator,
  ClientDataRuleItem,
  ClientDataRuleNumberOperator,
  ClientDataRuleTextOperator,
  ClientDataRuleValueType,
  MinimumPremiumRuleItem,
  RuleItem,
  RuleType,
  StateRuleItem,
  StateRuleOperator,
} from "./rule";

export default function EditRules({ rule }: { rule: RuleItem }) {
  const { setValue } = useFormContext();
  const [currentRule, setCurrentRule] = useState(rule);

  useEffect(() => {
    setValue("rule", currentRule);
  }, [currentRule]);

  return (
    <>
      <div className="mb-2">
        <h3>Edit Rule</h3>
        <DetailBadges rule={rule} />
      </div>

      <RuleEditor rule={currentRule} onRuleChange={setCurrentRule} />

      <div className="flex justify-center mt-4">
        <Button size="sm">Save</Button>
      </div>
    </>
  );
}

export function RuleEditor({ rule, onRuleChange }: { rule?: RuleItem; onRuleChange: (rule: RuleItem) => void }) {
  switch (rule?.type) {
    case RuleType.MinimumPremium:
      return <MinimumPremiumRule rule={rule} onRuleChange={onRuleChange} />;
    case RuleType.State:
      return <StateRule rule={rule} onRuleChange={onRuleChange} />;
    case RuleType.CGL:
      return <CglRule rule={rule} onRuleChange={onRuleChange} />;
    case RuleType.ClientData:
      return <ClientDataRule rule={rule} onRuleChange={onRuleChange} />;
    default:
      return null;
  }
}

function MinimumPremiumRule({
  rule,
  onRuleChange,
}: {
  rule?: MinimumPremiumRuleItem;
  onRuleChange: (rule: RuleItem) => void;
}) {
  return (
    <div>
      <Label>Minimum Premium</Label>
      <Input
        type="number"
        value={rule?.value}
        onChange={({ target }) => onRuleChange({ type: RuleType.MinimumPremium, value: Number(target.value) })}
      />
    </div>
  );
}

function StateRule({ rule, onRuleChange }: { rule?: StateRuleItem; onRuleChange: (rule: RuleItem) => void }) {
  return (
    <div>
      <Label>Operator</Label>
      <Select
        value={rule?.operator}
        onValueChange={(v: StateRuleOperator) =>
          onRuleChange({ type: RuleType.State, operator: v, value: rule?.value })
        }
      >
        <SelectTrigger>
          <SelectValue />
        </SelectTrigger>
        <SelectContent>
          {Object.values(StateRuleOperator).map((v) => (
            <SelectItem key={v} value={v}>
              {v}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      <Label>State</Label>
      <select
        className="block p-2 bg-background border rounded w-full"
        value={rule?.value}
        onChange={({ target }) => onRuleChange({ type: RuleType.State, operator: rule?.operator, value: target.value })}
      >
        {STRICT_STATE_OPTIONS.map((state) => (
          <option key={state.value} value={state.value}>
            {state.label}
          </option>
        ))}
      </select>
    </div>
  );
}

function CglRule({ rule, onRuleChange }: { rule?: CglRuleItem; onRuleChange: (rule: RuleItem) => void }) {
  return (
    <div>
      <Label>Operator</Label>
      <Select
        value={rule?.operator}
        onValueChange={(v: CglRuleOperator) => onRuleChange({ type: RuleType.CGL, operator: v, value: rule?.value })}
      >
        <SelectTrigger>
          <SelectValue />
        </SelectTrigger>
        <SelectContent>
          {Object.values(CglRuleOperator).map((v) => (
            <SelectItem key={v} value={v}>
              {v}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      <Label>CGL</Label>
      <Input
        value={rule?.value}
        onChange={({ target }) => onRuleChange({ type: RuleType.CGL, operator: rule?.operator, value: target.value })}
      />
    </div>
  );
}

function ClientDataRule({ rule, onRuleChange }: { rule?: ClientDataRuleItem; onRuleChange: (rule: RuleItem) => void }) {
  return (
    <div>
      <Label>Client Data Key</Label>
      <Input
        disabled
        type="text"
        value={rule?.key}
        onChange={({ target }) =>
          onRuleChange({
            type: RuleType.ClientData,
            key: target.value,
            operator: rule?.operator,
            valueType: rule?.valueType ?? ClientDataRuleValueType.Text,
            value: rule?.value,
          })
        }
      />

      <Label>Operator</Label>
      <Select
        value={rule?.operator}
        onValueChange={(v) =>
          onRuleChange({
            type: RuleType.ClientData,
            operator: v,
            key: rule?.key ?? "",
            valueType: rule?.valueType ?? ClientDataRuleValueType.Text,
            value: rule?.value,
          })
        }
      >
        <SelectTrigger>
          <SelectValue placeholder="select operator" />
        </SelectTrigger>
        <SelectContent>
          <ClientDataRuleOperatorItems valueType={rule?.valueType ?? ClientDataRuleValueType.Text} />
        </SelectContent>
      </Select>

      <Label>Client Data Value</Label>
      <Input
        type="text"
        value={rule?.value}
        onChange={({ target }) =>
          onRuleChange({
            type: RuleType.ClientData,
            value: target.value,
            key: rule?.key ?? "",
            valueType: rule?.valueType ?? ClientDataRuleValueType.Text,
            operator: rule?.operator,
          })
        }
      />
    </div>
  );
}

function ClientDataRuleOperatorItems({ valueType }: { valueType: ClientDataRuleValueType }) {
  switch (valueType) {
    case ClientDataRuleValueType.Text:
      return (
        <>
          {Object.values(ClientDataRuleTextOperator).map((v) => (
            <SelectItem key={v} value={v}>
              {v}
            </SelectItem>
          ))}
        </>
      );
    case ClientDataRuleValueType.Number:
      return (
        <>
          {Object.values(ClientDataRuleNumberOperator).map((v) => (
            <SelectItem key={v} value={v}>
              {v}
            </SelectItem>
          ))}
        </>
      );
    case ClientDataRuleValueType.Boolean:
      return (
        <>
          {Object.values(ClientDataRuleBooleanOperator).map((v) => (
            <SelectItem key={v} value={v}>
              {v}
            </SelectItem>
          ))}
        </>
      );
  }
}

function DetailBadges({ rule }: { rule: RuleItem }) {
  const valueTypeColor: Record<ClientDataRuleValueType, Parameters<typeof Badge>[0]["variant"]> = {
    [ClientDataRuleValueType.Text]: "pink",
    [ClientDataRuleValueType.Number]: "violet",
    [ClientDataRuleValueType.Boolean]: "teal",
  };
  return (
    <div className="flex gap items-center">
      <Badge variant={rule.type === "client-data" ? "amber" : "cyan"}>{rule.type}</Badge>
      {rule.type === "client-data" && <Badge variant={valueTypeColor[rule.valueType]}>{rule.valueType}</Badge>}
    </div>
  );
}
