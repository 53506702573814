import { useAtom } from "jotai";
import React, { useState } from "react";
import { useParams } from "react-router";

import { correspondenceAtom, deletedFilesAtom, fileLabelsAtom, foldersVisibleAtom, selectTagsAtom } from "@/atoms";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Card, CardTitle } from "@/components/ui/card";
import { Icon } from "@/components/ui/icon";
import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import { cn } from "@/utils";
import { Dialog, DialogTitle } from "@radix-ui/react-dialog";
import { format } from "date-fns";
import Dropzone from "react-dropzone";
import {
  FileUploadFragment,
  File_Audience,
  useFilesByLabelQuery,
  useFileTagsForSelectorQuery,
} from "../../generated/graphql";
import { uploadFiles } from "../../utils/file.utils";
import { HasInternalRole } from "../components/has-role";
import { DialogHeader } from "../components/ui/dialog";
import { Loading } from "../components/ui/loading";
import { useToast } from "../components/ui/use-toast";
import { EditFileDialog } from "../opportunity/components/file-upload/file-upload-dropzone";
import { FileMenu, ViewPDF } from "../opportunity/files/file-menu";
import { FilesOptions } from "../opportunity/files/files-options";
import { TagRow } from "../opportunity/files/tag-row";

export const InsuredFiles: React.FC = () => {
  const { insuredId } = useParams<"insuredId">();
  const [foldersVisible, setFoldersVisible] = useAtom(foldersVisibleAtom);
  const [correspondence, setCorrespondence] = useAtom(correspondenceAtom);
  const [selectedTags, setSelectedTags] = useAtom(selectTagsAtom);
  const [deletedFiles] = useAtom(deletedFilesAtom);
  const [fileLabels] = useAtom(fileLabelsAtom);
  const [selectedFile, setSelectedFile] = useState<FileUploadFragment | undefined>(undefined);

  const { data: { fileTags = [] } = {} } = useFileTagsForSelectorQuery({
    variables: {
      input: {
        insuredId: insuredId ?? "",
      },
    },
  });

  // Load files with direct link to opportunity first, since they're quick, and likely to include most files
  const {
    data: { filesByLabel: linkedFiles = [] } = {},
    loading,
    refetch: refetchLinkedFiles,
  } = useFilesByLabelQuery({
    variables: {
      input: {
        insuredId: insuredId || "",
        includeGmailAttachments: false,
        labels: selectedTags ? [selectedTags] : [],
        deletedAt: deletedFiles,
      },
    },
  });

  // Concurrently load all files (incl. email attachments), because this can take a while
  const { data: { filesByLabel: allFiles = [] } = {}, refetch: refetchAllFiles } = useFilesByLabelQuery({
    variables: {
      input: {
        insuredId: insuredId || "",
        includeGmailAttachments: true,
        labels: selectedTags ? [selectedTags] : [],
        deletedAt: deletedFiles,
      },
    },
  });

  let files = allFiles.length > 0 ? allFiles : linkedFiles;

  files = correspondence ? files : files.filter((file) => !file.labels.includes("Correspondence"));

  let tags = fileTags.filter((t) => t?.parentTags?.length === 0) || [];

  tags = correspondence ? fileTags : fileTags.filter((tag) => tag.label !== "Correspondence");

  const refetch = () => {
    refetchAllFiles();
    refetchLinkedFiles();
  };

  return (
    <InsuredExternalFileDropZone refetch={refetch}>
      <Card className="gap-px @5xl:grid grid-cols-4">
        <HasInternalRole>
          <div className="@5xl:border-r col-span-1">
            <header className="hidden @5xl:flex h-12 items-center justify-between p-4 pb-0">
              <CardTitle>Folders</CardTitle>
            </header>
            <header className="flex @5xl:hidden items-center justify-between p-4">
              <Button
                variant="outline"
                className="gap-3"
                onClick={() => {
                  setFoldersVisible((curr) => !curr);
                }}
              >
                <Icon icon="folder_open" />
                {selectedTags || "All Files"}
                <Icon
                  icon={foldersVisible ? "keyboard_arrow_up" : "keyboard_arrow_down"}
                  className="bg-accent rounded-full"
                />
              </Button>
              <FilesOptions />
            </header>
            <header className="border-b hidden @5xl:flex h-12 items-center px-4 text-xs text-muted-foreground">
              Name
            </header>
            <div className={cn(foldersVisible ? "block" : "hidden", "@5xl:block")}>
              {tags.map((t) => (
                <TagRow key={t.label} label={t.label} depth={0} />
              ))}
              <div className="border-t flex items-center gap-3 mt-px px-4 py-4 text-xs">
                <Switch
                  id="correspondence"
                  checked={correspondence}
                  onCheckedChange={(val) => {
                    setSelectedTags("");
                    setCorrespondence(val);
                  }}
                />
                <Label htmlFor="correspondence">Display Correspondence</Label>
              </div>
            </div>
          </div>
        </HasInternalRole>
        <div className="col-span-3 @5xl:border-l @5xl:block">
          <HasInternalRole>
            <header className="hidden @5xl:flex items-center justify-between p-4 pb-0">
              <CardTitle>{selectedTags || "All Files"}</CardTitle>
              <FilesOptions />
            </header>
          </HasInternalRole>
          <Row className="border-b h-12 mb-px text-muted-foreground">
            <Cell>File Name</Cell>
            <Cell>Uploaded By</Cell>
            <Cell>Date</Cell>
            <Cell />
          </Row>
          {loading ? (
            <Loading className="p-4" />
          ) : (
            <Dialog open={!!selectedFile} onOpenChange={() => setSelectedFile(undefined)}>
              <DialogHeader className="hidden">
                <DialogTitle>Files</DialogTitle>
              </DialogHeader>
              {files.map((file) => (
                <Row key={file?.id} className={cn("border-t", file?.deletedAt && "text-destructive")}>
                  <Cell
                    className="truncate"
                    onClick={() =>
                      setSelectedFile({
                        filename: file.filename,
                        id: file.id,
                        mimeType: file.mimeType,
                      })
                    }
                  >
                    <div className="truncate">{file?.filename}</div>
                    {fileLabels && file.labels[0] && (
                      <div className="flex flex-wrap gap-2 mt-1.5 text-3xs">
                        <Icon icon="subdirectory_arrow_right" className="ml-1.5 text-muted-foreground" />
                        {file.labels.map((label) => (
                          <Badge key={label} variant="secondary">
                            {label}
                          </Badge>
                        ))}
                      </div>
                    )}
                  </Cell>
                  <Cell>
                    {file.uploader?.firstName} {file.uploader?.lastName}
                  </Cell>
                  <Cell>{format(new Date(file.deletedAt ?? file.createdAt), "M/d/yy")}</Cell>
                  <Cell>
                    <FileMenu file={file} />
                  </Cell>
                </Row>
              ))}
              {selectedFile && <ViewPDF file={selectedFile} files={files} setSelectedFile={setSelectedFile} />}
            </Dialog>
          )}
        </div>
      </Card>
    </InsuredExternalFileDropZone>
  );
};

const InsuredExternalFileDropZone = ({ children, refetch }: React.PropsWithChildren & { refetch: () => void }) => {
  const { insuredId } = useParams<"insuredId">();
  const { toast } = useToast();
  const [fileId, setFileId] = useState(undefined);

  return (
    <Dropzone
      noClick
      onDrop={(files) => {
        toast({ title: "Loading...." });
        void uploadFiles(files, insuredId ?? "", File_Audience.External, "MANUAL_UPLOAD").then((fileId) => {
          if (fileId) {
            toast({ title: "File uploaded successfully" });
            setFileId(fileId);
          } else {
            toast({ title: "Error uploading file" });
          }
          refetch();
        });
      }}
    >
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps()} className="w-full">
          <input {...getInputProps()} />
          {children}
          <HasInternalRole>
            <Dialog onOpenChange={() => setFileId(undefined)} open={fileId ? true : false}>
              {fileId && <EditFileDialog fileId={fileId} setFileId={setFileId} />}
            </Dialog>
          </HasInternalRole>
        </div>
      )}
    </Dropzone>
  );
};

const Row = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      "grid gap-6 grid-flow-col grid-cols-[1fr_6rem_3.5rem_2rem] items-center px-4 py-3 text-xs",
      className
    )}
    {...props}
  />
);

const Cell = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cn("", className)} {...props} />
);
