import { Arrays, markdownToHtml } from "@cp/toolkit";
import { convert } from "html-to-text";
import { useNavigate } from "react-router";

import { HasInternalRole } from "@/components/has-role";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Icon } from "@/components/ui/icon";
import { CarrierContacts } from "@/insured/marketing-plan/carrier-contacts/carrier-contacts";
import { cn } from "@/utils";
import { BindingAuthority, OpportunityDetailsFragment } from "../../../../generated/graphql";
import { EmailTemplatesButton } from "./email-templates-button";
import { ProductSupportedLines } from "./lob-list";
import { MarketingEffortSubmission } from "./marketing-efforts";

interface Props {
  opportunity: OpportunityDetailsFragment;
  submissions: MarketingEffortSubmission[];
}

export const MarketingEffort: React.FC<Props> = ({ opportunity, submissions }) => {
  const subs = Arrays.sort(submissions, (a, b) => {
    const aRank = opportunity.verticalMarketingPlanTemplate?.products.find(
      (product) => product.appetiteProduct.id === a.appetiteProduct.id
    );
    const bRank = opportunity.verticalMarketingPlanTemplate?.products.find(
      (product) => product.appetiteProduct.id === b.appetiteProduct.id
    );

    if (aRank && bRank) {
      return aRank.rank - bRank.rank;
    }

    return a.appetiteProduct.carrierName.localeCompare(b.appetiteProduct.carrierName);
  });

  return (
    <>
      {subs.map((submission) => (
        <SubmissionRow key={submission.id} opportunity={opportunity} submission={submission} />
      ))}
    </>
  );
};

const SubmissionRow: React.FC<{
  opportunity: OpportunityDetailsFragment;
  submission: MarketingEffortSubmission;
}> = ({ opportunity, submission }) => {
  const { parsedText, googleDriveUrl } = submission.appetiteGuide ?? {};
  const hasBindingGuide = parsedText || googleDriveUrl;
  const formattedText = convert(parsedText!, {
    selectors: [
      { selector: "ul", options: { leadingLineBreaks: 0, trailingLineBreaks: 0 } },
      { selector: "a", options: { hideLinkHrefIfSameAsText: true } },
    ],
  });

  const htmlFormatted = markdownToHtml(formattedText)
    .replaceAll(/\[<a(.*?)>(.*)<\/a>]/g, '<a$1 target="_blank" style="color: blue">$2</a>')
    .replaceAll("<br>\n", "");

  return (
    <Row>
      <Cell>
        <span>{submission.appetiteProduct.carrierName}</span>
        <span
          title={submission.appetiteProduct.carrierProductName ?? ""}
          className="font-normal text-muted-foreground truncate"
        >
          {submission.appetiteProduct.carrierProductName}
        </span>
        <HasInternalRole>
          {submission.appetiteGuide && (
            <Dialog>
              <DialogTrigger asChild>
                <div className={`flex flex-row ${hasBindingGuide ? "hover:cursor-pointer hover:underline" : ""}`}>
                  {submission.appetiteGuide?.authority === BindingAuthority.InAuthority ? (
                    <div className=" text-success">In Authority</div>
                  ) : submission.appetiteGuide?.authority === BindingAuthority.Submit ? (
                    <div className=" text-amber-500">Submission Required</div>
                  ) : (
                    <div className=" text-red-900">Prohibited</div>
                  )}
                </div>
              </DialogTrigger>
              {hasBindingGuide && (
                <DialogContent
                  style={{
                    maxWidth: googleDriveUrl ? "80vw" : undefined,
                    height: "80vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className="whitespace-pre-wrap overflow-auto"
                >
                  <DialogHeader className="hidden">
                    <DialogTitle>Binding Guide</DialogTitle>
                  </DialogHeader>
                  {googleDriveUrl ? (
                    <iframe
                      src={googleDriveUrl}
                      style={{
                        width: "80vw",
                        height: "80vh",
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginTop: "2vh",
                      }}
                    />
                  ) : (
                    <div
                      className="max-h-full max-w-full text-wrap overflow-auto"
                      dangerouslySetInnerHTML={{ __html: htmlFormatted }}
                    />
                  )}
                </DialogContent>
              )}
            </Dialog>
          )}
        </HasInternalRole>
      </Cell>
      <Cell className="gap-4">
        <ProductSupportedLines
          product={submission.appetiteProduct}
          requestedLines={opportunity.selectedLinesOfBusiness}
        />
      </Cell>
      <Cell className="col-span-2 @3xl/efforts:col-auto">
        <HasInternalRole>
          <div className="flex items-center">
            <EmailTemplatesButton opportunity={opportunity} submission={submission} />
            <CarrierContacts submission={submission} />
            <QuoteProposalButton subId={submission.id} />
          </div>
        </HasInternalRole>
      </Cell>
    </Row>
  );
};

export const Row = ({ children, className }: { children: React.ReactNode; className?: string }) => (
  <div
    className={cn(
      "border-t gap-6 grid grid-cols-2 @3xl/efforts:grid-cols-[1fr_1fr_20rem] items-center p-4 pr-2 @3xl/efforts:py-2 relative text-xs",
      className
    )}
  >
    {children}
  </div>
);

export const Group = ({ children, className }: { children: React.ReactNode; className?: string }) => (
  <div className={cn("flex items-center justify-between", className)}>{children}</div>
);

export const Cell = ({ children, className }: { children?: React.ReactNode; className?: string }) => (
  <div className={cn("flex font-semibold gap-2 items-center leading-relaxed truncate", className)}>{children}</div>
);

const QuoteProposalButton = ({ subId }: { subId: string }) => {
  const nav = useNavigate();

  return (
    <Button
      variant="ghost"
      size="icon"
      onClick={() => {
        nav(`${subId}`);
      }}
    >
      <Icon icon="send" />
    </Button>
  );
};
