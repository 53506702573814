import { useParams } from "react-router";

import { HasInternalRole } from "@/components/has-role";
import { Button } from "@/components/ui/button";
import { Card, CardHeader, CardTitle } from "@/components/ui/card";
import { Coverage } from "@/opportunity/coverage";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  MarketingPlanState,
  MarketingPlanStateInformationGatheringActions,
  MarketingPlanStateInformationReviewActions,
  MarketingPlanStateMarketingActions,
  OnOpportunityStateChangedDocument,
  OnOpportunityStateChangedSubscription,
  OnOpportunityStateChangedSubscriptionVariables,
  OpportunityKitchenSinkQueryResult,
  useOpportunityKitchenSinkQuery,
  useTransitionMarketingPlanMutation,
} from "../../../generated/graphql";
import { useModal } from "../../components/modal-provider";
import { Requirements } from "../requirements/requirements";
import { SubmissionList } from "./submission";

export const MarketingPlanDetails = () => {
  const { opportunityId: marketingPlanId } = useParams<"opportunityId">();
  const { data, refetch, subscribeToMore } = useOpportunityKitchenSinkQuery({
    variables: {
      id: marketingPlanId ?? marketingPlanId!,
    },
    skip: !marketingPlanId,
  });

  useEffect(() => {
    if (!marketingPlanId) {
      return;
    }
    return subscribeToMore<OnOpportunityStateChangedSubscription, OnOpportunityStateChangedSubscriptionVariables>({
      document: OnOpportunityStateChangedDocument,
      variables: { id: marketingPlanId },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) {
          return prev;
        }

        return { opportunity: subscriptionData.data.opportunityStateChanged };
      },
      onError: (error) => {
        console.error("Subscription error:", error);
      },
    });
  }, [marketingPlanId]);

  const marketingPlan = data?.opportunity;

  if (!marketingPlan) {
    return null;
  }

  return <MarketingPlanStateRenderer marketingPlan={marketingPlan} refetch={refetch} />;
};

export type MarketingPlan = NonNullable<NonNullable<OpportunityKitchenSinkQueryResult["data"]>["opportunity"]>;

const MarketingPlanStateRenderer: React.FC<{ marketingPlan: MarketingPlan; refetch: () => void }> = ({
  marketingPlan,
  refetch,
}) => {
  const { state } = marketingPlan;

  const renderMarketingPlanState = () => {
    let content = null;
    switch (state) {
      case MarketingPlanState.WaitingForCoverages:
        content = <MarketingPlanWaitingForCoverages />;
        break;
      case MarketingPlanState.InformationGathering:
        content = <MarketingPlanInformationGathering marketingPlan={marketingPlan} />;
        break;
      case MarketingPlanState.InformationReview:
        content = <MarketingPlanInformationReview marketingPlan={marketingPlan} />;
        break;
      case MarketingPlanState.Marketing:
        content = (
          <>
            <MarketingPlanMarketing marketingPlan={marketingPlan} refetch={refetch} />
            <Requirements />
          </>
        );
        break;
      case MarketingPlanState.Complete:
        content = (
          <>
            <MarketingPlanComplete marketingPlan={marketingPlan} refetch={refetch} />
            <Requirements />
          </>
        );
        break;
      default:
        content = null;
    }
    return content;
  };

  return renderMarketingPlanState();
};

const MarketingPlanWaitingForCoverages = () => {
  return <Coverage skipNavigation />;
};

const MarketingPlanInformationGathering: React.FC<{ marketingPlan: MarketingPlan }> = ({ marketingPlan }) => {
  const [transition] = useTransitionMarketingPlanMutation();

  const handleTransition = async (action: MarketingPlanStateInformationGatheringActions) => {
    await transition({
      variables: {
        input: {
          marketingPlanId: marketingPlan.id,
          action,
        },
      },
    });
  };

  return (
    <Requirements
      actions={
        <Button
          onClick={() => handleTransition(MarketingPlanStateInformationGatheringActions.ReviewInformation)}
          variant="outline"
          size="sm"
        >
          Info Gathering Complete
        </Button>
      }
    />
  );
};

const MarketingPlanInformationReview: React.FC<{ marketingPlan: MarketingPlan }> = ({ marketingPlan }) => {
  const [transition] = useTransitionMarketingPlanMutation();

  const handleTransition = async (action: MarketingPlanStateInformationReviewActions) => {
    await transition({
      variables: {
        input: {
          marketingPlanId: marketingPlan.id,
          action,
        },
      },
    });
  };

  return (
    <Requirements
      actions={
        <Button
          onClick={() => handleTransition(MarketingPlanStateInformationReviewActions.StartMarketing)}
          variant="outline"
          size="sm"
        >
          Start Marketing
        </Button>
      }
    />
  );
};

const MarketingPlanMarketing: React.FC<{ marketingPlan: MarketingPlan; refetch: () => void }> = ({
  marketingPlan,
  refetch,
}) => {
  const { submissions, id: marketingPlanId } = marketingPlan;
  const [transition] = useTransitionMarketingPlanMutation();
  const { openConfirmation } = useModal();

  const handleTransition = async (action: MarketingPlanStateMarketingActions) => {
    const isConfirmed = await openConfirmation({
      title: "Confirm marketing complete",
    });
    if (!isConfirmed) {
      return;
    }

    await transition({
      variables: {
        input: {
          marketingPlanId: marketingPlanId,
          action,
        },
      },
    });
  };

  return (
    <MarketsCard
      actions={
        <>
          <Button asChild variant="outline" size="sm">
            <Link to="markets">Select Markets</Link>
          </Button>
          <Button
            onClick={() => handleTransition(MarketingPlanStateMarketingActions.CompleteMarketing)}
            variant="outline"
            size="sm"
          >
            Completed Marketing
          </Button>
        </>
      }
    >
      <SubmissionList submissions={submissions} refetch={refetch} />
    </MarketsCard>
  );
};

const MarketingPlanComplete: React.FC<{ marketingPlan: MarketingPlan; refetch: () => void }> = ({
  marketingPlan,
  refetch,
}) => {
  const { submissions } = marketingPlan;

  return (
    <MarketsCard>
      <SubmissionList submissions={submissions} refetch={refetch} />
    </MarketsCard>
  );
};

const MarketsCard = ({ children, actions }: { children: React.ReactNode; actions?: React.ReactNode }) => (
  <Card>
    <CardHeader className="bg-accent border-b rounded-t-lg">
      <CardTitle className="flex items-center justify-between">
        Markets
        <HasInternalRole>
          <div className="flex gap-2 items-center ml-auto">{actions}</div>
        </HasInternalRole>
      </CardTitle>
    </CardHeader>
    {children}
  </Card>
);
