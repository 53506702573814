import React, { useState } from "react";
import { useParams } from "react-router";

import { HasInternalRole } from "@/components/has-role";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Icon } from "@/components/ui/icon";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Spinner } from "@/components/ui/loading";
import { Textarea } from "@/components/ui/textarea";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { useToast } from "@/components/ui/use-toast";
import {
  File_Audience,
  MarketTypes,
  SubmissionFragment,
  SubmissionStateSubmittedActions,
  useSendReferralToCarrierMutation,
  useTransitionSubmissionMutation,
} from "../../../generated/graphql";
import { uploadFiles } from "../../../utils/file.utils";
import { useModal } from "../../components/modal-provider";
import { SubmissionActions, SubmissionDetails } from "./submission";
import { UploadQuote } from "./upload-quote";

interface FileInfo {
  id: string;
  name: string;
}

const EmailModal = ({
  isOpen,
  onClose,
  onSend,
  initialBody,
}: {
  isOpen: boolean;
  onClose: () => void;
  onSend: (emailComment: string, files: string[]) => void;
  initialBody: string;
}) => {
  const { opportunityId } = useParams<"opportunityId">();
  const { insuredId } = useParams<"insuredId">();
  const { toast } = useToast();
  const [emailComment, setEmailComment] = useState(initialBody);
  const [files, setFiles] = useState<FileInfo[]>([]);
  const [uploadingFile, setUploadingFile] = useState(false);

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];

      if (file.type !== "application/pdf") {
        toast({ title: "We only accept PDF files" });
        return;
      }
      if (!opportunityId) {
        toast({ title: "Error" });
        return;
      }
      try {
        setUploadingFile(true);
        const id = await uploadFiles([file], insuredId || "", File_Audience.Internal, "MANUAL_UPLOAD");
        if (id) {
          setFiles([...files, { id, name: file.name }]);
          setUploadingFile(false);
        }
      } catch {
        toast({ title: "Error uploading file", description: "Please try again" });
      }
    }
  };

  const handleRemoveFile = (idToRemove: string) => {
    setFiles(files.filter((file) => file.id !== idToRemove));
  };

  const handleSend = () => {
    onSend(
      emailComment,
      files.map((file) => file.id)
    );
    onClose();
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[525px]">
        <DialogHeader>
          <DialogTitle>Carrier Submission - Referral</DialogTitle>
        </DialogHeader>
        <div className="gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="email-body" className="col-span-4">
              Referral Reason and email notes
            </Label>
            <Textarea
              id="email-body"
              value={emailComment}
              onChange={(e) => setEmailComment(e.target.value)}
              className="col-span-4"
              rows={10}
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            {uploadingFile ? (
              <Spinner />
            ) : (
              <>
                <div className="col-span-4">Attach Files (PDF only)</div>
                <Input id="" type="file" onChange={handleFileChange} className="col-span-4" accept=".pdf" />{" "}
              </>
            )}
          </div>
          <div className="col-span-4">
            <Label>Attached Files:</Label>
            <ul className="mt-2 space-y-2">
              {files.map((file) => (
                <li key={file.id} className="flex items-center justify-between bg-gray-100 p-2 rounded">
                  <span>{file.name}</span>
                  <Button variant="ghost" size="icon-sm" onClick={() => handleRemoveFile(file.id)}>
                    <Icon icon="close" />
                  </Button>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <DialogFooter>
          <Button onClick={onClose} variant="outline">
            Cancel
          </Button>
          <Button onClick={handleSend}>Send Email</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export const SubmissionSubmitted = ({
  submission,
  refetch,
}: {
  submission: SubmissionFragment;
  refetch: () => void;
}) => {
  const { toast } = useToast();
  const { openConfirmation } = useModal();
  const { appetiteProduct, id } = submission;
  const { marketType } = appetiteProduct;
  const [sendSubmission] = useSendReferralToCarrierMutation();
  const [transition] = useTransitionSubmissionMutation();
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);

  const handleSendEmail = (emailComment: string, fileIds: string[]) => {
    void sendSubmission({
      variables: {
        input: {
          submissionId: id,
          emailComment: emailComment,
          fileIds,
        },
      },
      onCompleted: () => {
        toast({ title: "Email sent" });
      },
      onError: () => {
        toast({ title: "Error sending email" });
      },
    });
  };

  const handleTransition = async (action: SubmissionStateSubmittedActions) => {
    const isConfirmed = await openConfirmation({
      title: `Confirm ${action}`,
    });
    if (!isConfirmed) {
      return;
    }

    await transition({
      variables: {
        input: {
          submissionId: id,
          action,
        },
      },
      refetchQueries: ["OpportunityKitchenSink"],
    });
  };

  return (
    <>
      <SubmissionDetails submission={submission} />
      <SubmissionActions>
        <HasInternalRole>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                onClick={() => handleTransition(SubmissionStateSubmittedActions.BlockMarket)}
                variant="ghost"
                size="icon"
              >
                <Icon icon="front_hand" />
              </Button>
            </TooltipTrigger>
            <TooltipContent>Blocked</TooltipContent>
          </Tooltip>
          {marketType === MarketTypes.BindingAuthority && (
            <Button onClick={() => handleTransition(SubmissionStateSubmittedActions.Refer)} variant="outline" size="sm">
              Mark as Referred
            </Button>
          )}
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                onClick={() => handleTransition(SubmissionStateSubmittedActions.Decline)}
                variant="ghost"
                size="icon"
              >
                <Icon icon="thumb_down" />
              </Button>
            </TooltipTrigger>
            <TooltipContent>Declined</TooltipContent>
          </Tooltip>
          <UploadQuote submission={submission} refetch={refetch} />
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                onClick={() => handleTransition(SubmissionStateSubmittedActions.RuleOut)}
                variant="ghost"
                size="icon"
              >
                <Icon icon="close" />
              </Button>
            </TooltipTrigger>
            <TooltipContent>Rule Out</TooltipContent>
          </Tooltip>
          <EmailModal
            isOpen={isEmailModalOpen}
            onClose={() => setIsEmailModalOpen(false)}
            onSend={handleSendEmail}
            initialBody={""}
          />
        </HasInternalRole>
      </SubmissionActions>
    </>
  );
};
