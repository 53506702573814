import { Link } from "react-router-dom";

import { useMyAccount } from "@/auth/useMyAccount";
import { hasInternalRole, HasInternalRole } from "@/components/has-role";
import { Button } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { StateIndicator } from "@/metadata";
import { formatTimezoneDateString } from "src/utils/date";
import { formatDollars } from "src/utils/strings";
import { QuoteState, SubmissionFragment, SubmissionState } from "../../../generated/graphql";
import { sentToAgent } from "../insured-nav";
import { SubmissionPending } from "./submission-pending";
import { SubmissionReferred } from "./submission-referred";
import { SubmissionSubmitted } from "./submission-submitted";
import { UploadQuote } from "./upload-quote";

const SUBMISSION_ORDER = [
  SubmissionState.Quoted,
  SubmissionState.Submitted,
  SubmissionState.Referred,
  SubmissionState.Pending,
  SubmissionState.RuledOut,
  SubmissionState.MarketBlocked,
  SubmissionState.Declined,
];

export function SubmissionList({ submissions, refetch }: { submissions: SubmissionFragment[]; refetch: () => void }) {
  const { data: user } = useMyAccount();

  if (!user) {
    return null;
  }

  const visibleSubmissions = submissions.filter(
    (submission) => submission.state !== SubmissionState.Pending || user.internal
  );

  return visibleSubmissions[0] ? (
    <>
      <header className="bg-accent font-semibold gap-4 grid grid-cols-6 h-10 items-center px-4 text-2xs text-muted-foreground">
        <div className="col-span-2">Carrier / Product</div>
        <div>Status / Updated</div>
        <div className="col-span-3" />
      </header>
      {visibleSubmissions
        .sort((a, b) => {
          const aIndex = SUBMISSION_ORDER.indexOf(a.state);
          const bIndex = SUBMISSION_ORDER.indexOf(b.state);
          return aIndex - bIndex;
        })
        .map((submission) => (
          <div key={submission.id} className="border-t grid grid-cols-6 gap-4 min-h-16 items-center px-4 text-xs">
            <SubmisionStateRenderer submission={submission} refetch={refetch} />
          </div>
        ))}
    </>
  ) : (
    <div className="bg-accent p-4 rounded-b-lg text-sm">Marketing Plan pending.</div>
  );
}

const SubmisionStateRenderer: React.FC<{ submission: SubmissionFragment; refetch: () => void }> = ({
  submission,
  refetch,
}) => {
  const { state } = submission;

  const renderSubmissionState = () => {
    switch (state) {
      case SubmissionState.Pending:
        return <SubmissionPending submission={submission} />;
      case SubmissionState.Submitted:
        return <SubmissionSubmitted submission={submission} refetch={refetch} />;
      case SubmissionState.RuledOut:
        return <SubmissionRuledOut submission={submission} />;
      case SubmissionState.Declined:
        return <SubmissionDeclined submission={submission} />;
      case SubmissionState.Referred:
        return <SubmissionReferred submission={submission} />;
      case SubmissionState.MarketBlocked:
        return <SubmissionMarketBlocked submission={submission} />;
      case SubmissionState.Quoted:
        return <SubmissionQuoted submission={submission} refetch={refetch} />;
      default:
        return null;
    }
  };

  return renderSubmissionState();
};

const SubmissionRuledOut = ({ submission }: { submission: SubmissionFragment }) => {
  return <SubmissionDetails submission={submission} />;
};

const SubmissionMarketBlocked = ({ submission }: { submission: SubmissionFragment }) => {
  return <SubmissionDetails submission={submission} />;
};

const SubmissionDeclined = ({ submission }: { submission: SubmissionFragment }) => {
  return <SubmissionDetails submission={submission} />;
};

const SubmissionQuoted = ({ submission, refetch }: { submission: SubmissionFragment; refetch: () => void }) => (
  <>
    <SubmissionDetails submission={submission} />
    <SubmissionActions>
      <SubmissionQuotes submission={submission} />
      <HasInternalRole>
        <UploadQuote submission={submission} refetch={refetch} />
        <Button variant="ghost" size="icon" disabled>
          <Icon icon="close" />
        </Button>
      </HasInternalRole>
    </SubmissionActions>
  </>
);

export const SubmissionDetails = ({ submission }: { submission: SubmissionFragment }) => {
  const { appetiteProduct, state, stateTransitions, createdAt } = submission;
  const { carrierName, carrierProductName } = appetiteProduct;
  const lastTransition = stateTransitions.length > 0 ? stateTransitions[stateTransitions.length - 1] : { createdAt };

  return (
    <>
      <div className="col-span-2 space-y-1">
        <div className="flex font-semibold items-center">{carrierName}</div>
        <div className="text-2xs truncate">{carrierProductName}</div>
      </div>
      <div className="space-y-1">
        <div className="font-semibold">
          <StateIndicator state={state} />
        </div>
        <div className="text-2xs">{formatTimezoneDateString(lastTransition.createdAt, "MMM d, yyyy")}</div>
      </div>
    </>
  );
};

export const SubmissionActions = ({ children }: { children: React.ReactNode }) => (
  <div className="col-span-3 flex gap-2 items-center justify-end">{children}</div>
);

export const SubmissionQuotes = ({ submission }: { submission: SubmissionFragment }) => {
  const { data: user } = useMyAccount();

  if (!user) {
    return null;
  }

  const { quotes } = submission;

  const validQuotes = hasInternalRole(user)
    ? quotes.filter((quote) => quote.state !== QuoteState.Invalid)
    : quotes.filter((quote) => sentToAgent(quote));

  return (
    <>
      {validQuotes.map((quote) => (
        <div key={quote.id}>
          <Button variant="outline" size="sm" className="block h-auto items-center px-2.5 py-1.5 space-y-0.5" asChild>
            <Link to={`../quotes/${quote.id}`}>
              <div className="font-semibold text-[11px]">
                {quote.premium ? formatDollars(quote.premium) : "Unprocessed"}
              </div>
              <StateIndicator state={quote.state} className="text-3xs" />
            </Link>
          </Button>
        </div>
      ))}
    </>
  );
};
