import { getAuthRequestHeaders } from "@cp/auth";
import { useState } from "react";
import { useParams } from "react-router";

import { HasInternalRole } from "@/components/has-role";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Icon } from "@/components/ui/icon";
import { Spinner } from "@/components/ui/loading";
import { toast } from "@/components/ui/use-toast";
import { StateLabel } from "@/metadata";
import { cn } from "@/utils";
import { formatTimezoneDateString } from "src/utils/date";
import {
  PolicyFragment,
  PolicyState,
  PolicyStateIssuedActions,
  useTransitionPolicyMutation,
  useUpdatePolicyMutation,
} from "../../../generated/graphql";
import { uploadPolicyDocument } from "../../../utils/file.utils";
import { useMyAccount } from "../../auth/useMyAccount";
import { useModal } from "../../components/modal-provider";
import { Toggle } from "../../components/ui/toggle";
import { PolicyReceivedFromCarrier } from "./policy-recieved-from-carrier";

export const PolicyStateRenderer: React.FC<{ policy: PolicyFragment }> = ({ policy }) => {
  const renderPolicyState = () => {
    switch (policy.state) {
      case PolicyState.AwaitingFromCarrier:
        return <PolicyAwaitingFromCarrier policy={policy} />;
      case PolicyState.ReceivedFromCarrier:
        return <PolicyReceivedFromCarrier policy={policy} />;
      case PolicyState.Issued:
        return <PolicyIssued policy={policy} />;
      case PolicyState.Canceled:
        return <PolicyCanceled policy={policy} />;
      default:
        return null;
    }
  };

  return renderPolicyState();
};

const PolicyAwaitingFromCarrier: React.FC<{ policy: PolicyFragment }> = ({ policy }) => {
  const { id: policyId, policyFileId } = policy;
  const [submitting, setSubmitting] = useState(false);
  const [updatePolicy] = useUpdatePolicyMutation();
  const { insuredId } = useParams<"insuredId">();
  const updateFileForProcessing = async (fileId: string) => {
    await updatePolicy({
      variables: {
        input: {
          policyId,
          policyFileId: fileId,
        },
      },
      refetchQueries: ["Policy"],
      onCompleted() {
        toast({ title: "Policy File Saved" });
      },
      onError() {
        toast({ title: "An error occurred." });
      },
    });
  };

  return (
    <div className="space-y-3">
      <PolicyInfo policy={policy} />
      <div className="grid grid-cols-2 gap-3">
        {policyFileId && <PolicyFiles policy={policy} />}
        <PolicyActions>
          {!policyFileId && (
            <Button asChild variant="outline" size="sm">
              <label className={cn(submitting ? "cursor-wait opacity-60" : "cursor-pointer")}>
                <input
                  type="file"
                  name="file"
                  className="hidden"
                  onChange={async (e) => {
                    setSubmitting(true);
                    if (e.target.files && e.target.files.length > 0) {
                      const file = e.target.files[0];

                      if (file.type !== "application/pdf") {
                        toast({ title: "We only accept PDF files" });
                        setSubmitting(false);
                        return;
                      }
                      if (!policy) {
                        toast({ title: "Error" });
                        setSubmitting(false);
                        return;
                      }
                      if (!insuredId) {
                        toast({ title: "Error" });
                        setSubmitting(false);
                        return;
                      }

                      const { fileId } = await uploadPolicyDocument(file, policyId);
                      await updateFileForProcessing(fileId);
                    }
                    setSubmitting(false);
                  }}
                />
                {submitting ? <Spinner /> : "Upload Policy File"}
              </label>
            </Button>
          )}
        </PolicyActions>
      </div>
    </div>
  );
};

const PolicyIssued = ({ policy }: { policy: PolicyFragment }) => {
  const { openConfirmation } = useModal();

  const [transition] = useTransitionPolicyMutation();

  const handleTransition = async (action: PolicyStateIssuedActions) => {
    const isConfirmed = await openConfirmation({
      title: `Confirm ${action} Policy`,
    });
    if (!isConfirmed) {
      return;
    }

    await transition({
      variables: {
        input: {
          policyId: policy.id,
          action,
        },
      },
    });
  };

  return (
    <div className="space-y-3">
      <PolicyInfo policy={policy} />
      <div className="grid grid-cols-2 gap-3">
        <PolicyFiles policy={policy} />
        <PolicyActions>
          <Button onClick={() => handleTransition(PolicyStateIssuedActions.Cancel)} variant="outline" size="sm">
            Cancel Policy
          </Button>
        </PolicyActions>
      </div>
    </div>
  );
};

const PolicyCanceled = ({ policy }: { policy: PolicyFragment }) => {
  return (
    <div className="space-y-3">
      <PolicyInfo policy={policy} />
      <div className="grid grid-cols-2 gap-3">
        <PolicyFiles policy={policy} />
      </div>
    </div>
  );
};

export const PolicyFiles: React.FC<{ policy: PolicyFragment }> = ({ policy }) => {
  const { data } = useMyAccount();
  const { policyFile, binderFile, state } = policy;
  if (data?.internal === false && state !== PolicyState.Issued) {
    return null;
  }
  return (
    <Card>
      <CardHeader>
        <CardTitle>Files</CardTitle>
      </CardHeader>
      <CardContent className="flex flex-col gap-2">
        {policyFile && (
          <a
            key={policyFile?.id}
            href={`/api/files/${policyFile?.id}`}
            download={policyFile?.filename}
            media={policyFile?.mimeType}
            className="flex gap-2 items-start leading-tight hover:text-primary"
            onClick={async (e) => {
              e.preventDefault();

              const blob = await fetch(`/api/files/${policyFile?.id}`, {
                headers: getAuthRequestHeaders(),
              })
                .then((r) => r.blob())
                .then((b) => b.slice(0, b.size, policyFile?.mimeType));

              const url = URL.createObjectURL(blob);
              const anchor = document.createElement("a");
              anchor.href = url;
              anchor.download = policyFile?.filename;

              document.body.append(anchor);
              anchor.click();

              URL.revokeObjectURL(url);
              anchor.remove();
            }}
          >
            <Card className="flex flex-col gap-2 content-center p-4">
              <span>
                <Badge variant="outline" className="text-xs">
                  Policy
                </Badge>
              </span>
              <div className="flex gap-2 content-center">
                <Icon icon="attachment" className="scale-150" />
                {policyFile?.filename}
              </div>
            </Card>
          </a>
        )}
        {binderFile && (
          <a
            key={binderFile?.id}
            href={`/api/files/${binderFile?.id}`}
            download={binderFile?.filename}
            media={binderFile?.mimeType}
            className="flex gap-2 items-start leading-tight hover:text-primary"
            onClick={async (e) => {
              e.preventDefault();

              const blob = await fetch(`/api/files/${binderFile?.id}`, {
                headers: getAuthRequestHeaders(),
              })
                .then((r) => r.blob())
                .then((b) => b.slice(0, b.size, binderFile?.mimeType));

              const url = URL.createObjectURL(blob);
              const anchor = document.createElement("a");
              anchor.href = url;
              anchor.download = binderFile?.filename;

              document.body.append(anchor);
              anchor.click();

              URL.revokeObjectURL(url);
              anchor.remove();
            }}
          >
            {binderFile.filename}
            <Badge className="text-2xs">Binder</Badge>
          </a>
        )}
      </CardContent>
    </Card>
  );
};

export const PolicyInfo: React.FC<{ policy: PolicyFragment }> = ({ policy }) => {
  const {
    id: policyId,
    policyNumber,
    awaitingSubjectivities,
    endorsementsRequested,
    state,
    stateTransitions,
    createdAt,
    effectiveDate,
    expirationDate,
    appetiteProduct,
  } = policy;
  const lastTransition = stateTransitions.length > 0 ? stateTransitions[stateTransitions.length - 1] : { createdAt };

  const [mutation, { loading }] = useUpdatePolicyMutation();

  const handleAwaitingSubjectivitiesChange = async (checked: boolean) => {
    await mutation({
      variables: {
        input: {
          policyId,
          awaitingSubjectivities: checked,
        },
      },
    });
  };

  const handleEndorsementsRequestedChange = async (checked: boolean) => {
    await mutation({
      variables: {
        input: {
          policyId,
          endorsementsRequested: checked,
        },
      },
    });
  };

  return (
    <Card>
      <CardHeader className="bg-accent border-b rounded-t-lg">
        <CardTitle>
          {appetiteProduct.carrierName}: {appetiteProduct.carrierProductName}
        </CardTitle>
        <CardDescription>
          <StateLabel state={state} />
        </CardDescription>
      </CardHeader>
      <CardContent className="gap-4 grid grid-cols-3 p-4">
        <dl>
          <dt>Policy Number</dt>
          <dd>#{policyNumber}</dd>
        </dl>
        <dl>
          <dt>Awaiting Subjectivities</dt>
          <Toggle onPressedChange={handleAwaitingSubjectivitiesChange} disabled={loading}>
            {awaitingSubjectivities ? "Yes" : "No"}
          </Toggle>
        </dl>
        <dl>
          <dt>Endorsements Requested</dt>
          <Toggle onPressedChange={handleEndorsementsRequestedChange} disabled={loading}>
            {endorsementsRequested ? "Yes" : "No"}
          </Toggle>
        </dl>
        <dl>
          <dt>Effective Date</dt>
          <dd>{formatTimezoneDateString(effectiveDate, "MMMM d, yyyy")}</dd>
        </dl>
        <dl>
          <dt>Expiration Date</dt>
          <dd>{formatTimezoneDateString(expirationDate, "MMMM d, yyyy")}</dd>
        </dl>
        <dl>
          <dt>Last Updated</dt>
          <dd>{formatTimezoneDateString(lastTransition.createdAt, "MMMM d, yyyy")}</dd>
        </dl>
      </CardContent>
    </Card>
  );
};

export const PolicyActions = ({ children }: { children: React.ReactNode }) => (
  <HasInternalRole>
    <Card>
      <CardHeader>
        <CardTitle>Actions</CardTitle>
      </CardHeader>
      <CardContent className="flex flex-col gap-2">{children}</CardContent>
    </Card>
  </HasInternalRole>
);
