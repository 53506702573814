import { useState } from "react";

import { Icon } from "@/components/ui/icon";
import { StateIndicator, States } from "@/metadata";
import { cn } from "@/utils";

export const KanbanColumn = ({ children, id, count }: { children: React.ReactNode; id: States; count: number }) => {
  const [expanded, setExpanded] = useState(count > 0);

  return (
    <div
      className={cn(
        "max-md:border-y md:border-x first:border-l-0 flex flex-col max-md:mt-px z-0",
        expanded && "flex-none w-80"
      )}
    >
      <button
        className={cn(
          "border-b flex flex-none h-12 items-center justify-between px-3 text-xs",
          count > 0 ? "bg-background" : "bg-accent"
        )}
        onClick={() => setExpanded((expanded) => !expanded)}
      >
        {expanded ? (
          <>
            <StateIndicator state={id} />
            <span className="bg-foreground/[0.03] flex flex-none gap items-center px-[0.5em] py-[0.125em] rounded text-2xs">
              <Icon icon={expanded ? "keyboard_double_arrow_left" : "keyboard_double_arrow_right"} /> {count}
            </span>
          </>
        ) : (
          <StateIndicator state={id} displayLabel={false} />
        )}
      </button>
      {expanded && <div className="bg-accent flex-auto overflow-auto p-3 space-y-3">{children}</div>}
    </div>
  );
};
