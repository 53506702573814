import { groupBy } from "lodash";
import { Outlet, useParams } from "react-router";
import { NavLink } from "react-router-dom";

import { HasInternalRole } from "@/components/has-role";
import { Card } from "@/components/ui/card";
import { cn } from "@/utils";
import { formatTimezoneDateString } from "src/utils/date";
import { QuoteFragment, useQuotesQuery } from "../../generated/graphql";

export function InsuredQuotes() {
  const { insuredId } = useParams<"insuredId">();
  const { data: { quotes } = { quotes: [] } } = useQuotesQuery({
    variables: {
      input: {
        insuredId: insuredId ?? insuredId!,
      },
    },
    skip: !insuredId,
  });

  if (!quotes) {
    return null;
  }

  const groupedQuotes = groupBy(quotes, (quotes) => quotes.submissionId);

  return (
    <div className="@container/opp flex flex-auto flex-col min-w-0" id="OpportunityRoot">
      <Card className="flex h-16 items-center px-4 rounded-none landscape:rounded-bl-lg sticky text-xl top-0 z-20">
        <div className="font-semibold mr-auto">Quotes</div>
      </Card>
      <div className="flex gap-3 p-3 pl-0">
        <div className="flex-auto space-y-3">
          <Outlet />
        </div>
        <HasInternalRole>
          <div className="flex-none space-y-3 w-80">
            {Object.keys(groupedQuotes).map((submission) => {
              const { carrierName, carrierProductName } = groupedQuotes[submission][0].submission.appetiteProduct;

              return (
                <Card key={submission}>
                  <header className="bg-accent p-4 rounded-t-lg text-xs truncate">
                    <span className="font-semibold truncate">{carrierName}</span>: {carrierProductName}
                  </header>
                  {groupedQuotes[submission].map((quote: QuoteFragment, key) => {
                    const lastTransition = [...quote.stateTransitions].pop() ?? { createdAt: quote.createdAt };
                    return (
                      <NavLink
                        key={quote.id}
                        to={quote.id}
                        className={({ isActive, isPending }) =>
                          cn(
                            "border-t flex gap-2 p-4 text-xs",
                            isPending && "opacity-75 shadow-unselected",
                            isActive && "shadow-selected"
                          )
                        }
                      >
                        <div className="flex-none font-semibold truncate w-4">{key + 1}</div>
                        <div className="flex flex-auto gap-2 justify-between">
                          <span>{quote.state}</span>
                          <span>{formatTimezoneDateString(lastTransition?.createdAt, "MMM d, yyyy")}</span>
                        </div>
                      </NavLink>
                    );
                  })}
                </Card>
              );
            })}
          </div>
        </HasInternalRole>
      </div>
    </div>
  );
}
