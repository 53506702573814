import { useParams } from "react-router";
import { usePolicyQuery } from "../../../generated/graphql";
import { Activity } from "../activity/activity";
import { PolicyStateRenderer } from "./policy";

export function PolicyDetails() {
  const { policyId } = useParams<"policyId">();
  const { data: { policy } = {} } = usePolicyQuery({
    variables: {
      id: policyId ?? policyId!,
    },
    skip: !policyId,
  });

  if (!policy) {
    return null;
  }

  return (
    <>
      <PolicyStateRenderer policy={policy} />
      <Activity opportunityId={policy.opportunityId} />
    </>
  );
}
