import { Outlet } from "react-router";
import { useDocumentTitle } from "usehooks-ts";

import { HasRole } from "@/components/has-role";
import { PageContent } from "@/components/layout";
import { Bar, BarLink } from "@/components/ui/bar";
import { Roles } from "src/generated/graphql";

const settingsPages = [
  { to: "/admin/enablement", label: "Enablement" },
  { to: "/admin/appetite", label: "Appetite" },
  { to: "/admin/appetite-notes", label: "Appetite Notes" },
  { to: "/admin/agency-ownership", label: "Agency Ownership" },
  { to: "/admin/gmail-filters", label: "Gmail Filters" },
  { to: "/admin/verticals", label: "Verticals" },
  { to: "/admin/requirements", label: "Requirements" },
];

export function AdminRoot() {
  useDocumentTitle("Admin: QuoteWell");
  return (
    <HasRole roles={[Roles.Admin]}>
      <PageContent id="AdminRoot">
        <Bar as="header" position="sticky" className="justify-start">
          {settingsPages.map(({ label, to }) => (
            <BarLink key={to} to={to}>
              {label}
            </BarLink>
          ))}
        </Bar>
        <Outlet />
      </PageContent>
    </HasRole>
  );
}
