import { SearchParamSelect } from "@/components/ui/search-param-select";
import { SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { OpportunityGroupBy } from "../../../generated/graphql";

export const SelectGroup = () => (
  <SearchParamSelect name="groupBy">
    <SelectTrigger>
      <SelectValue />
    </SelectTrigger>
    <SelectContent>
      <SelectItem value={OpportunityGroupBy.Agent}>Agent</SelectItem>
      <SelectItem value={OpportunityGroupBy.Broker}>Broker</SelectItem>
      <SelectItem value={OpportunityGroupBy.EffectiveDate}>Effective Date</SelectItem>
      <SelectItem value={OpportunityGroupBy.CreatedAt}>Date Created</SelectItem>
      <SelectItem value="">Date Updated</SelectItem>
    </SelectContent>
  </SearchParamSelect>
);
