import { filter } from "lodash";
import { useParams } from "react-router";

import { adaptSearchParams } from "@/components/adapt-search-params";
import { Loading } from "@/components/ui/loading";
import { Pane } from "@/components/ui/pane";
import { useUpsertSearchParams } from "@/hooks/useUpsertSearchParams";
import {
  MarketingPlanState,
  PolicyState,
  QuoteState,
  useBankanMarketingPlansQuery,
  useBankanPoliciesQuery,
  useBankanQuotesQuery,
} from "../../generated/graphql";
import { KanbanColumn } from "./kanban-column";
import { MarketingPlansCard } from "./marketing-plans-card";
import { PoliciesCard } from "./policies-card";
import { QuotesCard } from "./quotes-card";

// const now = Date.now();

const planStates = [MarketingPlanState.InformationReview, MarketingPlanState.Marketing];
const quoteStates = [QuoteState.Processed, QuoteState.Delivered, QuoteState.BindRequestReceived];
const policyStates = [PolicyState.AwaitingFromCarrier, PolicyState.ReceivedFromCarrier];

export const KanbanColumns = () => {
  const { brokerId } = useParams();
  const [searchParams] = useUpsertSearchParams();
  const { ...filterArgs } = adaptSearchParams(searchParams);

  const { data: { bankanMarketingPlans } = {}, loading: plansLoading } = useBankanMarketingPlansQuery({
    variables: {
      input: {
        agencyId: filterArgs.agencyId,
        brokerId: brokerId,
      },
    },
  });

  const { data: { bankanQuotes } = {}, loading: quotesLoading } = useBankanQuotesQuery({
    variables: {
      input: {
        agencyId: filterArgs.agencyId,
        brokerId: brokerId,
      },
    },
  });

  const { data: { bankanPolicies } = {}, loading: policiesLoading } = useBankanPoliciesQuery({
    variables: {
      input: {
        agencyId: filterArgs.agencyId,
        brokerId: brokerId,
      },
    },
  });

  if (plansLoading || quotesLoading || policiesLoading) {
    return (
      <Pane>
        <Loading />
      </Pane>
    );
  }

  if (!bankanMarketingPlans || !bankanPolicies || !bankanQuotes) {
    return null;
  }

  // const plans = data.paginatedOpportunities.opportunities.map((opp) => ({
  //   ...opp,
  //   daysToEffDate: Math.floor((now - Date.parse(opp.desiredEffectiveDate)) / (1000 * 60 * 60 * 24)),
  // }));

  // const sortedPlans = plans?.sort((a, b) => Math.abs(a.daysToEffDate) - Math.abs(b.daysToEffDate));

  return (
    <section className="bg-accent md:flex flex-auto gap-px overflow-auto w-full">
      {planStates.map((state) => {
        const filtered = filter(bankanMarketingPlans.groupedMarketingPlans, { marketingPlans: [{ state }] });
        return (
          <KanbanColumn key={state} id={state} count={filtered.length}>
            {filtered.map((insured) => (
              <MarketingPlansCard key={insured.insuredId} insured={insured} highlightState={state} />
            ))}
          </KanbanColumn>
        );
      })}
      {quoteStates.map((state) => {
        const filtered = filter(bankanQuotes.groupedQuotes, { quotes: [{ state }] });
        return (
          <KanbanColumn key={state} id={state} count={filtered.length}>
            {filtered.map((insured) => (
              <QuotesCard key={insured.insuredId} insured={insured} highlightState={state} />
            ))}
          </KanbanColumn>
        );
      })}
      {policyStates.map((state) => {
        const filtered = filter(bankanPolicies.groupedPolicies, { policies: [{ state }] });
        return (
          <KanbanColumn key={state} id={state} count={filtered.length}>
            {filtered.map((insured) => (
              <PoliciesCard key={insured.insuredId} insured={insured} highlightState={state} />
            ))}
          </KanbanColumn>
        );
      })}
    </section>
  );
};
