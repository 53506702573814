import { useState } from "react";
import { useParams } from "react-router";

import { HasInternalRole } from "@/components/has-role";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Icon } from "@/components/ui/icon";
import { OpportunityEmailTemplate, useCreateEmailTemplateDraftMutation } from "../../../generated/graphql";
import { useToast } from "../../components/ui/use-toast";
import { MarketingEffortSubmission } from "../../opportunity/details/marketing-efforts/marketing-efforts";
import { SubmissionTemplateModal } from "../../opportunity/details/marketing-efforts/submission-template-modal";
import { parseError } from "../../utils";

interface Props {
  submission: MarketingEffortSubmission;
}

export const EmailTemplatesButton: React.FC<Props> = ({ submission }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { insuredId } = useParams<{ insuredId: string }>();
  if (!insuredId) {
    return null;
  }
  return (
    <HasInternalRole>
      <DropdownMenu open={dropdownOpen} onOpenChange={setDropdownOpen} modal={false}>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" size="icon" onClick={() => setDropdownOpen(true)}>
            <Icon icon="mail" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-full">
          <DropdownMenuLabel>Email Templates</DropdownMenuLabel>
          <DropdownMenuSeparator />
          <EmailTemplateAction
            label="Send Submission to Carrier"
            template={OpportunityEmailTemplate.SubmitToCarrier}
            clientId={insuredId}
            submissionId={submission.id}
            onModalClose={() => setDropdownOpen(false)}
          />
        </DropdownMenuContent>
      </DropdownMenu>
    </HasInternalRole>
  );
};

interface EmailTemplateActionProps {
  label: string;
  clientId: string;
  submissionId: string;
  template: OpportunityEmailTemplate;
  onModalClose: () => void;
}

const EmailTemplateAction: React.FC<EmailTemplateActionProps> = ({
  label,
  clientId,
  submissionId,
  template,
  onModalClose,
}) => {
  const { opportunityId } = useParams<"opportunityId">();
  const { toast } = useToast();
  const [modalOpen, setModalOpen] = useState(false);
  const [createEmailTemplateDraft] = useCreateEmailTemplateDraftMutation();

  const handleCreateEmailDraft = async (fileIds: string[]) => {
    toast({ title: "Creating Email Draft", description: "Please wait..." });
    await createEmailTemplateDraft({
      variables: {
        input: {
          submissionId,
          template,
          fileIds: fileIds,
        },
      },
      onCompleted: (data) => {
        if (data?.createEmailTemplateDraft) {
          window.open(data.createEmailTemplateDraft, "_blank");
          setModalOpen(false);
        }
      },
      onError: (error) => {
        toast({ title: "Error", description: parseError(error), variant: "destructive" });
      },
    });
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (template === OpportunityEmailTemplate.SubmitToCarrier) {
      e.preventDefault();
      setModalOpen(true);
    } else {
      void handleCreateEmailDraft([]);
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
    onModalClose();
  };

  return (
    <>
      <DropdownMenuItem onClick={handleClick}>{label}</DropdownMenuItem>
      <SubmissionTemplateModal
        open={modalOpen}
        onClose={handleModalClose}
        clientId={clientId}
        opportunityId={opportunityId ?? ""}
        handleCreateEmailDraft={handleCreateEmailDraft}
      />
    </>
  );
};
