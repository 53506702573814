import { FormEvent, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useMyAccount } from "@/auth/useMyAccount";
import { Icon } from "@/components/ui/icon";
import { FetchResult } from "@apollo/client";
import { CreateInsuredAndAgentMutation, CreateInsuredMutation, File_Audience } from "../../../generated/graphql";
import { uploadFiles } from "../../../utils/file.utils";
import { OrEmailDisplay } from "../../components/or-email";
import { SubmissionTipCard, UploadTipCard } from "./appetite/appetite-cards";
import { InsuredForm } from "./appetite/appetite-form";
import { AgentFormSchema, useCreateInsured2 } from "./appetite/use-create-opportunity";
import { useLoadFromDoc } from "./appetite/use-load-from-doc";

export type SetValuesFn = (keyVals: Array<[string, string | undefined]>) => void;

export const CreateInsuredPage: React.FC = () => {
  const { data: user } = useMyAccount();
  const [file, setFile] = useState<File>();

  const [submitting, setSubmitting] = useState(false);

  const { zodResolver, createInsured } = useCreateInsured2(user);

  const formMethods = useForm<z.infer<typeof AgentFormSchema>>({
    resolver: zodResolver,
  });

  const setFormValues: SetValuesFn = (keyVals) => {
    keyVals.forEach(([k, v]) => {
      formMethods.setValue(k as keyof z.infer<typeof AgentFormSchema>, v);
    });
    void formMethods.trigger();
  };

  const { parentProps, onClick, ...docUploadMethods } = useLoadFromDoc(setFormValues, setFile);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    const isValid = await formMethods.trigger();
    e.preventDefault();

    if (isValid) {
      const values = formMethods.getValues();
      setSubmitting(true);
      void createInsured(values).then(async (res) => {
        let insuredId;

        if (isCreateInsuredAndAgent(res)) {
          insuredId = res.data?.createInsuredAndAgent.id; // Access BrokerCreateOpportunity properties
        } else if (isCreateInsured(res)) {
          insuredId = res.data?.createInsured.id; // Access AgentCreateOpportunity properties
        }

        if (file && insuredId) {
          await uploadFiles([file], insuredId, File_Audience.External, "MANUAL_UPLOAD");
        }

        setSubmitting(false);
      });
    }
  };

  return (
    <div className="flex flex-auto max-lg:flex-col gap-4 max-w-6xl mx-auto p-4" {...parentProps}>
      <InsuredForm
        formMethods={formMethods}
        uploadInProgress={docUploadMethods.loading}
        onSubmit={onSubmit}
        submitting={submitting}
      />
      <div className="flex-none space-y-4 lg:w-1/3">
        <SubmissionTipCard />
        <UploadTipCard clickToUpload={onClick} documentUploadMethods={docUploadMethods} />
        <OrEmailDisplay className="w-full" />
      </div>
      {docUploadMethods.dragging ? (
        <div className="absolute w-screen h-screen opacity-70 bg-popover flex justify-center items-center">
          <p className="mb-48 flex items-center gap-2 text-xl font-semibold ">
            <Icon icon="upload" className="animate-bounce" />
            Drop file to start upload
          </p>
        </div>
      ) : null}
    </div>
  );
};

function isCreateInsuredAndAgent(
  res: FetchResult<CreateInsuredAndAgentMutation> | FetchResult<CreateInsuredMutation>
): res is FetchResult<CreateInsuredAndAgentMutation> {
  return (res as FetchResult<CreateInsuredAndAgentMutation>)?.data?.createInsuredAndAgent !== undefined;
}

function isCreateInsured(
  res: FetchResult<CreateInsuredAndAgentMutation> | FetchResult<CreateInsuredMutation>
): res is FetchResult<CreateInsuredMutation> {
  return (res as FetchResult<CreateInsuredMutation>)?.data?.createInsured !== undefined;
}
