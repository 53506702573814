import { KnownClientData } from "@qw/qw-common";

import InputElement from "./input-element";

export default function SubjectOfInsuranceElement({ index }: { index: number }) {
  return (
    <div>
      <InputElement
        label={`Subject of Insurance #${index + 1}`}
        clientDataKey={`${KnownClientData.SubjectOfInsurance.Subject}::${index}`}
      />
      <InputElement
        label={`Amount #${index + 1}`}
        clientDataKey={`${KnownClientData.SubjectOfInsurance.Amount}::${index}`}
      />
      <InputElement
        label={`Cause of Loss #${index + 1}`}
        clientDataKey={`${KnownClientData.SubjectOfInsurance.CauseOfLoss}::${index}`}
      />
    </div>
  );
}
