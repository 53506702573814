import { ActionFunctionArgs, Link, useNavigate } from "react-router-dom";

import { useMyAccount } from "@/auth/useMyAccount";
import { HasInternalRole } from "@/components/has-role";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Icon } from "@/components/ui/icon";
import { useToast } from "@/components/ui/use-toast";
import {
  DeleteOpportunityDocument,
  DeleteOpportunityMutation,
  DeleteOpportunityMutationVariables,
  Roles,
  useDeleteOpportunityMutation,
  useReassignOpportunityMutation,
} from "../../../generated/graphql";
import { apolloClient } from "../../../graphql/client";
import { CreateEmail } from "../../opportunity/components/create-email";
import { reloadOpportunity, useOpportunity } from "../../opportunity/loaders";

export const opportunityActions = async ({ request, params }: ActionFunctionArgs) => {
  switch (request.method) {
    case "delete":
    case "DELETE":
      return deleteOpportunityAction({ request, params });
    default:
      return null;
  }
};

export const deleteOpportunityAction = async ({ params }: ActionFunctionArgs) => {
  const { opportunityId } = params;

  if (!opportunityId) {
    return;
  }

  const comment = await apolloClient.mutate<DeleteOpportunityMutation, DeleteOpportunityMutationVariables>({
    mutation: DeleteOpportunityDocument,
    variables: {
      id: opportunityId,
    },
  });

  return comment;
};

export const MarketingPlanDropdown: React.FC = () => {
  const { data: user } = useMyAccount();
  const { opportunity } = useOpportunity();
  const navigate = useNavigate();
  const { toast } = useToast();
  // const [createRenewal] = useCreateRenewalMutation();
  const [deleteOpportunity] = useDeleteOpportunityMutation();

  if (!opportunity) {
    return null;
  }

  // const allowRenewal = opportunity.submissions.some((s) => s.status === SubmissionStatus.IssuedFileComplete);

  const allowReassignment =
    user?.roles.includes(Roles.Dj) || user?.roles.includes(Roles.Admin) || opportunity.broker === null;

  const [reassignOpportunity] = useReassignOpportunityMutation({
    onCompleted: () => {
      toast({ title: "Success" });
    },
    onError: () => {
      toast({ title: "Error", description: "An error occurred.", variant: "destructive" });
    },
  });

  // const handleCreateRenewal = async () => {
  //   await createRenewal({
  //     variables: { input: { originalId: opportunity.id } },
  //     onCompleted: (data) => {
  //       toast({ title: "Renewal Created" });
  //       navigate(`/opportunity/${data.createRenewal.id}`);
  //     },
  //     refetchQueries: [...reloadOpportunity.refetchQueries, "GroupedOpportunityList"],
  //   });
  // };

  const handleDelete = async () => {
    await deleteOpportunity({
      variables: {
        id: opportunity.id,
      },
      onCompleted: () => {
        toast({ title: "Opportunity Deleted" });
        navigate("/opportunity");
      },
      onError: () => {
        toast({ title: "Error", description: "An error occurred.", variant: "destructive" });
      },
      refetchQueries: [...reloadOpportunity.refetchQueries, "GroupedOpportunityList"],
    });
  };

  return (
    <AlertDialog>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button size="icon" variant="ghost" className="text-base">
            <Icon icon="more_vert" className="font-bold" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuItem
            onClick={async () => {
              const link = `${window.location.origin}/insured/${opportunity.insuredId}/${opportunity.id}`;
              await navigator.clipboard.writeText(link);
              toast({ title: "Copied Link to Clipboard", description: link });
            }}
          >
            <Icon icon="link" className="mr-3" /> Copy Link to Clipboard
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => window.open(window.location.href, "_blank")}>
            <Icon icon="tab_duplicate" className="mr-3" /> Open in New Tab
          </DropdownMenuItem>
          <HasInternalRole>
            <DropdownMenuSeparator />
            <CreateEmail opportunityId={opportunity.id} />
            <DropdownMenuSeparator />
            <Link to="escape-hatch">
              <DropdownMenuItem>
                <Icon icon="eject" className="mr-3" />
                Binding Authority Escape Hatch
              </DropdownMenuItem>
            </Link>
            <DropdownMenuItem
              disabled={!allowReassignment}
              onClick={() => {
                void reassignOpportunity({
                  variables: {
                    id: opportunity.id,
                  },
                });
              }}
            >
              <Icon icon="account_circle" className="mr-3" /> Reassign Opportunity
            </DropdownMenuItem>
            {/* <DropdownMenuItem disabled={!allowRenewal} onClick={handleCreateRenewal}>
              <Icon icon="cycle" className="mr-3" /> Create Renewal
            </DropdownMenuItem> */}
            <AlertDialogTrigger asChild>
              <DropdownMenuItem className="text-destructive">
                <Icon icon="delete" className="mr-3" /> Delete Opportunity
              </DropdownMenuItem>
            </AlertDialogTrigger>
          </HasInternalRole>
        </DropdownMenuContent>
      </DropdownMenu>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Delete Opportunity</AlertDialogTitle>
          <AlertDialogDescription>Are you sure?</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={handleDelete} variant="destructive">
            Continue
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
