import { ContinueButton } from "@/appetite/components/continue-button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { STRICT_STATE_OPTIONS } from "@cp/toolkit";
import { FormEvent } from "react";
import { FormProvider, UseFormReturn } from "react-hook-form";
import { z } from "zod";
import { Card } from "../components/ui/card";
import { CurrencyFormField } from "../components/ui/currency-form-field";
import { DatePicker } from "../components/ui/date-picker";
import { Field, FieldInput, fieldInputClassName } from "../components/ui/field-inputs";
import { FormField, FormItem } from "../components/ui/form";
import { Textarea } from "../components/ui/textarea";
import { cn } from "../utils";
import { FirstSubmissionSchema } from "./first-submission-page";

interface FirstSubmissionFormProps {
  formMethods: UseFormReturn<z.infer<typeof FirstSubmissionSchema>>;
  onSubmit: (e: FormEvent<HTMLFormElement>) => Promise<void>;
  submitting: boolean;
}

export const FirstSubmissionForm: React.FC<FirstSubmissionFormProps> = ({ formMethods, onSubmit, submitting }) => {
  const { register } = formMethods;

  return (
    <div className="flex-auto">
      <FormProvider {...formMethods}>
        <form id="appetiteForm" onSubmit={(e) => onSubmit(e)}>
          <Card className="bg-muted overflow-hidden space-y-px">
            <FieldInput
              icon="badge"
              placeholder="Agent First Name*"
              className={fieldInputClassName}
              required={true}
              {...register("firstName")}
            />
            <FieldInput
              icon="badge"
              placeholder="Agent Last Name*"
              className={fieldInputClassName}
              required={true}
              {...register("lastName")}
            />
            <FieldInput
              icon="alternate_email"
              placeholder="Agent Email*"
              className={fieldInputClassName}
              required={true}
              {...register("agentEmail")}
            />
            <FieldInput
              icon="call"
              placeholder="Agent Phone"
              className={fieldInputClassName}
              {...register("agentPhone")}
            />
            <FieldInput
              icon="business_center"
              placeholder="Agency Name*"
              className={fieldInputClassName}
              required={true}
              {...register("agencyName")}
            />
            <Field icon="location_on">
              <FormField
                control={formMethods.control}
                name="agencyPrimaryState"
                render={({ field }) => (
                  <FormItem className="flex-auto">
                    <Select key={field.value} onValueChange={field.onChange} value={field.value} required>
                      <SelectTrigger className={fieldInputClassName}>
                        <SelectValue placeholder="Agency Primary State*" />
                      </SelectTrigger>
                      <SelectContent>
                        {STRICT_STATE_OPTIONS.map((state) => (
                          <SelectItem key={state.value} value={state.value}>
                            {state.label}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormItem>
                )}
              />
            </Field>
          </Card>
          <Card className="bg-muted overflow-hidden space-y-px mt-8">
            <div className="flex flex-col space-y-px">
              <FieldInput
                icon="business_center"
                placeholder="Insured Name (This is how the Named Insured will appear on the Policy)"
                required={true}
                {...formMethods.register("clientName")}
              />
              <Field icon="location_on">
                <FormField
                  control={formMethods.control}
                  name="clientState"
                  render={({ field }) => (
                    <FormItem className="flex-auto">
                      <Select key={field.value} onValueChange={field.onChange} value={field.value} required>
                        <SelectTrigger className={fieldInputClassName}>
                          <SelectValue placeholder="Primary State" />
                        </SelectTrigger>
                        <SelectContent>
                          {STRICT_STATE_OPTIONS.map((state) => (
                            <SelectItem key={state.value} value={state.value}>
                              {state.label}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </FormItem>
                  )}
                />
              </Field>
              <div className="flex flex-row gap-1 justify-between">
                <Field icon="event" className="w-full">
                  <DatePicker
                    placeholder="Desired Effective Date"
                    selected={formMethods.watch("desiredEffectiveDate")}
                    onSelect={(e) => {
                      e && formMethods.setValue("desiredEffectiveDate", e?.toISOString());
                    }}
                    className="pl-3 pr-3.5"
                  />
                </Field>
              </div>

              <CurrencyFormField
                autoFocus
                icon="attach_money"
                control={formMethods.control}
                name="revenue"
                required={false}
                placeholder="Gross Revenue"
              />
              <Field icon="storefront" className="items-start py-2" iconClassName="mt-2.5">
                <Textarea
                  placeholder="Business Description"
                  className={cn("resize-none", fieldInputClassName)}
                  required={true}
                  {...formMethods.register("description")}
                />
              </Field>
            </div>
          </Card>
        </form>
      </FormProvider>
      <div className="bottom-4 portrait:bottom-16 flex justify-end mt-4 sticky z-10">
        <ContinueButton form="appetiteForm" disabled={!formMethods.formState.isValid} submitting={submitting} />
      </div>
    </div>
  );
};
