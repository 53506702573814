import * as Select from "@radix-ui/react-select";
import { useNavigate, useParams } from "react-router";

import { HasInternalRole } from "@/components/has-role";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { SelectContent, SelectItem } from "@/components/ui/select";
import { useToast } from "@/components/ui/use-toast";
import { ApplicantClassification } from "@/opportunity/info/applicant-classification";
import { ApplicantDetails } from "@/opportunity/info/applicant-details";
import {
  useCreateOpportunityMutation,
  useInsuredQuery,
  useUpdateInsuredMutation,
  useUserAccountsQuery,
} from "../../generated/graphql";
import { UUIDScalar } from "../../utils/types";
import { HeaderCell, Trigger } from "./marketing-plan/marketing-plan-header";

export const InsuredIndex: React.FC = () => {
  const { insuredId } = useParams();
  const { toast } = useToast();
  const { data: { insured } = {} } = useInsuredQuery({
    variables: {
      id: insuredId ?? "",
    },
    skip: !insuredId,
    fetchPolicy: "cache-and-network",
  });

  const { data: agents } = useUserAccountsQuery({
    variables: { input: { agencyId: insured?.agency.id } },
    fetchPolicy: "cache-first",
  });

  const [updateInsured] = useUpdateInsuredMutation({
    onCompleted: () => {
      toast({ title: "Success", description: "Insured updated." });
    },
    onError: () => {
      toast({ title: "Error", description: "An error occurred.", variant: "destructive" });
    },
  });

  if (!insured) {
    return null;
  }

  const { id, agent } = insured;

  const SelectAgent = ({ children }: { children: React.ReactNode }) => (
    <HasInternalRole elseShow={children}>
      <Select.Root
        value={agent.id}
        // onValueChange={(e) =>
        onValueChange={() =>
          updateInsured({
            variables: {
              input: {
                id,
                //TODO: make this work
                //agentId: e as UUID
              },
            },
          })
        }
      >
        <Trigger>{children}</Trigger>
        <SelectContent>
          {agents?.userAccounts.map((u) => (
            <SelectItem key={u.id} value={u.id}>
              {u.firstName} {u.lastName}
            </SelectItem>
          ))}
        </SelectContent>
      </Select.Root>
    </HasInternalRole>
  );

  return (
    <div className="@container/opp flex flex-auto flex-col min-w-0" id="OpportunityRoot">
      <Card className="flex h-16 items-center px-4 rounded-none landscape:rounded-bl-lg sticky text-xl top-0 z-20">
        <div className="font-semibold mr-auto">Insured Details</div>
        <SelectAgent>
          <HeaderCell title={`${agent.firstName} ${agent.lastName}`} subtitle={agent.agency.name} />
        </SelectAgent>
      </Card>
      <div className="flex gap-3 p-3 pl-0">
        <div className="flex-auto space-y-3">
          <Card className="bg-border flex gap-px overflow-hidden">
            <div className="basis-1/2 bg-background flex-auto">
              <ApplicantDetails insured={insured} />
            </div>
            <div className="basis-1/2 bg-background flex-auto">
              <ApplicantClassification insured={insured} />
            </div>
          </Card>
          <CreateMarketingPlanButton insuredId={id} />
        </div>
      </div>
    </div>
  );
};

const CreateMarketingPlanButton = ({ insuredId }: { insuredId: string }) => {
  const navigate = useNavigate();
  const [createOpportunity] = useCreateOpportunityMutation({
    onCompleted: (data) => navigate(`${data.createOpportunity.id}`),
  });

  return (
    <Button
      onClick={() => {
        void createOpportunity({
          variables: {
            input: {
              insuredId: insuredId as UUIDScalar,
              desiredEffectiveDate: new Date(),
            },
          },
          onCompleted: (data) => {
            navigate(`/insured/${insuredId}/${data.createOpportunity.id}`);
          },
          refetchQueries: ["InsuredOpportunities"],
        });
      }}
    >
      Create Marketing Plan
    </Button>
  );
};
