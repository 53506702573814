import { useParams } from "react-router";

import { useQuoteQuery } from "../../../generated/graphql";
import { Activity } from "../activity/activity";
import { QuoteStateRenderer } from "./quote";

export function QuoteDetails() {
  const { quoteId } = useParams<"quoteId">();

  const { data: { quote } = {} } = useQuoteQuery({
    variables: {
      id: quoteId ?? quoteId!,
    },
    skip: !quoteId,
  });

  if (!quote) {
    return null;
  }

  return (
    <>
      <QuoteStateRenderer quote={quote} />
      <Activity opportunityId={quote.submission.opportunityId} />
    </>
  );
}
