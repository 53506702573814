import { NavLink } from "react-router-dom";

import { StateIndicator } from "@/metadata";
import { cn } from "@/utils";
import { OpportunityListItemFragment } from "../../generated/graphql";
import { formatTimezoneDateString } from "../../utils/date";
import { HasInternalRole } from "./has-role";
import { Avatar } from "./ui/avatar";
import { InsuredPin } from "./ui/insured-pin";

export const OpportunityListItem = ({
  opportunity,
}: {
  path?: string;
  opportunity: OpportunityListItemFragment;
  params?: Record<string, string>;
}) => {
  return (
    <NavLink
      to={`/insured/${opportunity.insuredId}/${opportunity.id}`}
      className={({ isActive, isPending }) =>
        cn(
          "@container/item bg-background gap-3 flex h-16 items-center pl-3 pr-1.5 overflow-hidden text-foreground/70 transition-all w-full",
          isPending && "text-foreground shadow-unselected",
          isActive && "text-foreground shadow-selected"
        )
      }
    >
      <Avatar user={opportunity.broker} className="flex-none text-xs" />
      <div className="flex-auto gap-4 grid @md:grid-cols-2 @2xl:grid-cols-3 items-center">
        <div className="leading-4 space-y-2 truncate">
          <div className="flex gap-2 items-center">
            <div className="font-semibold leading-4 text-xs truncate">{opportunity.insured.name}</div>
          </div>
          <div className="flex gap-2 items-center text-2xs leading-3">
            <StateIndicator state={opportunity.state} />
          </div>
        </div>
        <div className="hidden opacity-0 @md/item:block @md/item:opacity-100 leading-4 space-y-2 transition-opacity truncate">
          <div className="font-semibold leading-4 text-xs truncate">
            {opportunity.selectedLinesOfBusiness.join(", ")}
          </div>
          <div className="text-2xs leading-3">
            {formatTimezoneDateString(opportunity.desiredEffectiveDate, "MMM d")}
          </div>
        </div>
        <HasInternalRole>
          <div className="hidden opacity-0 @2xl/item:block @2xl/item:opacity-100 leading-4 space-y-2 transition-opacity truncate">
            <div className="font-semibold leading-4 text-xs truncate">{opportunity.insured.agent.agency.name}</div>
            <div className="text-2xs leading-3">
              {opportunity.insured.agent.firstName} {opportunity.insured.agent.lastName}
            </div>
          </div>
        </HasInternalRole>
      </div>
      <InsuredPin id={opportunity.id} />
    </NavLink>
  );
};
