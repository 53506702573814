import { Link } from "react-router-dom";

import { Icon } from "@/components/ui/icon";
import { STATE_METADATA } from "@/metadata";
import { cn } from "@/utils";
import { BankanMarketingPlansQuery, MarketingPlanState } from "src/generated/graphql";
import { formatTimezoneDateString } from "src/utils/date";

type BankanInsured = BankanMarketingPlansQuery["bankanMarketingPlans"]["groupedMarketingPlans"][number];

export const MarketingPlansCard = ({
  insured,
  highlightState,
}: {
  insured: BankanInsured;
  highlightState: MarketingPlanState;
}) => {
  const { insuredId, insuredName, marketingPlans } = insured;
  return (
    <div key={insuredId}>
      <header className="group bg-muted border border-b-0 flex gap-1 items-center justify-between px-4 pt-3 pb-5 rounded-t-lg text-xs">
        <span className="font-semibold truncate">{insuredName}</span>
      </header>
      <div className="bg-background -mt-2 rounded-lg">
        {marketingPlans.map((plan) => {
          const { id, desiredEffectiveDate, selectedLinesOfBusiness, state } = plan;
          const { cardClassName } = STATE_METADATA[state];
          return (
            <Link
              key={id}
              to={`/insured/${insuredId}/${id}`}
              className={cn(
                "group border flex flex-col -mt-px px-4 py-3 space-y-1.5 first:rounded-t-lg last:rounded-b-lg",
                state === highlightState && `${cardClassName} relative z-1`
              )}
            >
              <div className="flex font-semibold gap-4 items-start justify-between text-xs">
                <div className="space-y-0.5">
                  {selectedLinesOfBusiness.map((lob) => (
                    <div key={lob}>{lob}</div>
                  ))}
                </div>
                <Icon icon="arrow_forward" className="opacity-25 group-hover:opacity-100 transition-opacity" />
              </div>
              <div className="text-2xs">{formatTimezoneDateString(desiredEffectiveDate, "MMM d, yyyy")}</div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};
