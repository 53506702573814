import { useAtom } from "jotai";

import { oppInfoAtom } from "@/atoms";
import { HasInternalRole } from "@/components/has-role";
import { cn } from "@/utils";
import { UserAccountFragment } from "src/generated/graphql";
import { FollowUpActions } from "../components/actions/follow-up-actions";
import { useOpportunity } from "../loaders";
import { AgencyAffiliationBanner } from "../quote-proposal/agency-affiliation-banner";
import { ApplicantClassification } from "./applicant-classification";
import { ApplicantDetails } from "./applicant-details";
import { Files } from "./files";
import { LeadsDetails } from "./leads-details";
import { Renewal } from "./renewal";
import { Team } from "./team";

export const OpportunityInfo = () => {
  const [oppInfo] = useAtom(oppInfoAtom);
  const { opportunity, refetch } = useOpportunity();

  if (!opportunity) {
    return null;
  }

  const { id, broker, insured, brokerageFolderId, files, leads } = opportunity;
  const { agent } = insured;

  return (
    <div
      className={cn(
        "absolute bottom-0 right-0 top-0 bg-background ease-in-out-quart flex-none shadow-[0_0_1rem_rgba(0_0_0/0.1)] space-px transition-all z-10",
        "@4xl/opp:border-l @4xl/opp:relative @4xl/opp:shadow-none",
        oppInfo ? "w-full @xl/opp:w-96" : "overflow-hidden w-0"
      )}
    >
      <div className="bg-border min-w-96 space-y-px">
        <HasInternalRole>
          <AgencyAffiliationBanner />
          <FollowUpActions />
        </HasInternalRole>
        <Renewal />
        <Files
          files={files}
          opportunityId={id}
          insuredId={insured.id}
          brokerageFolderId={brokerageFolderId}
          refetch={refetch}
        />
        <ApplicantDetails insured={insured} />
        <LeadsDetails leads={leads} />
        <ApplicantClassification insured={insured} />
        <Team
          broker={broker as UserAccountFragment}
          agencyOwner={agent.agency.owner as UserAccountFragment}
          agent={agent}
        />
      </div>
    </div>
  );
};
