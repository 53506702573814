import { isEqual } from "lodash";
import { NavLink as UnstyledNavLink, NavLinkProps, useNavigate, useParams } from "react-router-dom";

import { HasInternalRole } from "@/components/has-role";
import { Bar } from "@/components/ui/bar";
import { Button } from "@/components/ui/button";
import { CollapsibleList, CollapsibleListContent, CollapsibleListTrigger } from "@/components/ui/collapsible-list";
import { Icon } from "@/components/ui/icon";
import { PaneGroup } from "@/components/ui/pane";
import { StateIndicator } from "@/metadata";
import { cn } from "@/utils";
import {
  AppetiteOpportunityFragment,
  QuoteFragment,
  QuoteState,
  useAgencyAffiliationQuery,
  useCreateOpportunityMutation,
  useInsuredOpportunitiesQuery,
  useInsuredQuery,
  usePoliciesQuery,
  useQuotesQuery,
  useVerticalByCglQuery,
} from "src/generated/graphql";
import { formatTimezoneDateString } from "src/utils/date";
import { formatDollars } from "src/utils/strings";
import { UUIDScalar } from "../../utils/types";

export const InsuredNav = ({ insuredId }: { insuredId: string }) => {
  const { data: { insured } = {} } = useInsuredQuery({
    variables: {
      id: insuredId,
    },
  });

  const { data: { insuredOpportunities: opportunities } = { insuredOpportunities: [] } } = useInsuredOpportunitiesQuery(
    {
      variables: {
        id: insuredId,
      },
    }
  );

  if (!insured) {
    return null;
  }

  return (
    <PaneGroup className="flex-none shadow w-64">
      <Bar as="header" className="p-0">
        <NavLink to={`/insured/${insured.id}`} end className="block flex-auto space-y-1 truncate">
          <div className="font-semibold text-sm truncate">{insured.name}</div>
          <div className="text-2xs truncate">{insured.agency?.name}</div>
        </NavLink>
      </Bar>
      <nav className="bg-accent flex flex-auto flex-col overflow-auto">
        <MarketingPlanNavSection opportunities={opportunities} />
        <QuotesNavSection insuredId={insuredId} />
        <PoliciesNavSection insuredId={insuredId} />
        <div className="bg-border border-t mt-auto space-y-px">
          <MarketingPlanTemplateSection cgl={insured.isoCglCodes[0]} opportunities={opportunities} />
          <HasInternalRole>{insured.agency?.id && <Affiliations id={insured.agency?.id} />}</HasInternalRole>
          <NavLink to="files">
            Files
            <Icon icon="upload" />
          </NavLink>
          <NavLink to="email">
            Email
            <Icon icon="mail" />
          </NavLink>
        </div>
      </nav>
    </PaneGroup>
  );
};

export const sentToAgent = (quote: QuoteFragment) =>
  quote.state === QuoteState.Delivered ||
  quote.state === QuoteState.NotTaken ||
  quote.state === QuoteState.BindRequestReceived ||
  quote.state === QuoteState.Bound;

const QuotesNavSection: React.FC<{ insuredId: string }> = ({ insuredId }) => {
  const { data: { quotes } = { quotes: [] } } = useQuotesQuery({
    variables: {
      input: {
        insuredId,
      },
    },
  });

  const validQuotes = quotes.filter((quote) => quote.state !== QuoteState.Invalid);
  const drafts = validQuotes.filter((quote) => !sentToAgent(quote));
  const proposals = validQuotes.filter((quote) => sentToAgent(quote));

  return (
    <>
      <HasInternalRole>
        {drafts[0] && (
          <CollapsibleList defaultOpen={true}>
            <CollapsibleListTrigger className="text-2xs text-muted-foreground uppercase">
              <div className="flex">
                <span className="flex-auto font-semibold">Quote Drafts</span>
                <span>{drafts.length}</span>
              </div>
            </CollapsibleListTrigger>
            <CollapsibleListContent>
              {drafts.map((quote) => {
                const { id: quoteId, submission, premium, state } = quote;
                const { appetiteProduct } = submission;
                const { carrierName, carrierProductName } = appetiteProduct;
                return (
                  <NavLink key={quoteId} to={`/insured/${insuredId}/quotes/${quoteId}`} className="block space-y-1">
                    <div className="font-semibold text-xs truncate">
                      {carrierName}: {carrierProductName}
                    </div>
                    <div className="flex justify-between text-2xs">
                      <StateIndicator state={state} />
                      <span>{premium && formatDollars(premium)}</span>
                    </div>
                  </NavLink>
                );
              })}
            </CollapsibleListContent>
          </CollapsibleList>
        )}
      </HasInternalRole>
      {proposals[0] && (
        <CollapsibleList defaultOpen={true}>
          <CollapsibleListTrigger className="text-2xs text-muted-foreground uppercase">
            <div className="flex">
              <span className="flex-auto font-semibold">Quotes</span>
              <span>{proposals.length}</span>
            </div>
          </CollapsibleListTrigger>
          <CollapsibleListContent>
            {proposals.map((quote) => {
              const { id: quoteId, submission, premium, state } = quote;
              const { appetiteProduct } = submission;
              const { carrierName, carrierProductName } = appetiteProduct;
              return (
                <NavLink key={quoteId} to={`/insured/${insuredId}/quotes/${quoteId}`} className="block space-y-2">
                  <div className="font-semibold text-xs truncate">
                    {carrierName}: {carrierProductName}
                  </div>
                  <div className="flex justify-between text-2xs">
                    <StateIndicator state={state} />
                    <span>{premium && formatDollars(premium)}</span>
                  </div>
                </NavLink>
              );
            })}
          </CollapsibleListContent>
        </CollapsibleList>
      )}
    </>
  );
};

const PoliciesNavSection: React.FC<{ insuredId: string }> = ({ insuredId }) => {
  const { data: { policies } = { policies: [] } } = usePoliciesQuery({
    variables: {
      input: {
        insuredId,
      },
    },
  });

  if (policies.length === 0) {
    return null;
  }

  return (
    <CollapsibleList defaultOpen={true}>
      <CollapsibleListTrigger className="text-2xs text-muted-foreground uppercase">
        <div className="flex">
          <span className="flex-auto font-semibold">Policies</span>
          <span>{policies.length}</span>
        </div>
      </CollapsibleListTrigger>
      <CollapsibleListContent>
        {policies.map((policy) => {
          const { id: policyId, state, policyNumber, appetiteProduct } = policy;
          const { carrierName, carrierProductName } = appetiteProduct;
          return (
            <NavLink key={policyId} to={`/insured/${insuredId}/policies/${policyId}`} className="block space-y-1">
              <div className="font-semibold text-xs truncate">
                {carrierName}: {carrierProductName}
              </div>
              <div className="flex justify-between text-2xs">
                <StateIndicator state={state} />
                <span>#{policyNumber}</span>
              </div>
            </NavLink>
          );
        })}
      </CollapsibleListContent>
    </CollapsibleList>
  );
};

const MarketingPlanNavSection: React.FC<{ opportunities: AppetiteOpportunityFragment[] }> = ({ opportunities }) => {
  if (opportunities.length === 0) {
    return null;
  }

  return (
    <CollapsibleList defaultOpen={true}>
      <CollapsibleListTrigger className="text-2xs text-muted-foreground uppercase">
        <div className="flex">
          <span className="flex-auto font-semibold">Marketing Plans</span>
          <span className="text-2xs">{opportunities.length}</span>
        </div>
      </CollapsibleListTrigger>
      <CollapsibleListContent>
        {opportunities.map((opp) => (
          <NavLink key={opp.id} to={opp.id} className="block space-y-1">
            <div className="flex items-start justify-between">
              <div className="font-semibold space-y-0.5 text-xs">
                {opp.selectedLinesOfBusiness[0]
                  ? opp.selectedLinesOfBusiness.map((lob) => <div key={lob}>{lob}</div>)
                  : "No Coverage Selected"}
              </div>
              {opp.renewalOf && <Icon icon="cycle" />}
            </div>
            <div className="flex justify-between text-2xs">
              <StateIndicator state={opp.state} />

              <span>{formatTimezoneDateString(opp.desiredEffectiveDate, "MMM d, yyyy")}</span>
            </div>
          </NavLink>
        ))}
      </CollapsibleListContent>
    </CollapsibleList>
  );
};

const navLinkClassName = "bg-background flex items-center justify-between px-4 py-3 text-sm text-foreground";

const NavLink = ({ to, children, className, ...rest }: NavLinkProps) => (
  <UnstyledNavLink
    to={to}
    className={({ isActive, isPending }) =>
      cn(navLinkClassName, isPending && "opacity-75 shadow-unselected", isActive && "shadow-selected", className)
    }
    {...rest}
  >
    {children}
  </UnstyledNavLink>
);

export const Affiliations = ({ id }: { id: string }) => {
  const { data } = useAgencyAffiliationQuery({
    variables: {
      id: id ?? "",
    },
  });

  if (!data) {
    return null;
  }

  return (
    <>
      {data.agency.affiliations?.map((affiliation) => (
        <div key={affiliation.name} className="bg-background flex gap-2 h-12 items-center px-4">
          <div className="flex-auto text-sm">
            {affiliation.logoUrl ? (
              <img src={affiliation?.logoUrl ?? ""} className="max-h-8 max-w-24" />
            ) : (
              <span className="truncate">{affiliation?.shortName}</span>
            )}
          </div>
          <div className="flex-none gap grid grid-cols-2 items-center leading-none text-2xs w-24">
            {affiliation.brokerageCommission && (
              <>
                <span>Brokerage:</span>
                <span className="text-right">{`${Number(affiliation?.brokerageCommission).toFixed(2)}%`}</span>
              </>
            )}
            {affiliation.bindingCommission && (
              <>
                <span>Binding:</span>
                <span className="text-right">{`${Number(affiliation?.bindingCommission).toFixed(2)}%`} </span>
              </>
            )}
          </div>
        </div>
      ))}
    </>
  );
};

const MarketingPlanTemplateSection = ({
  cgl,
  opportunities,
}: {
  cgl: string | undefined;
  opportunities: AppetiteOpportunityFragment[];
}) => {
  const navigate = useNavigate();
  const { insuredId } = useParams();
  const [createOpportunity] = useCreateOpportunityMutation({
    onCompleted: (data) => navigate(`${data.createOpportunity.id}`),
  });
  const { data: { verticalByCGL } = {} } = useVerticalByCglQuery({
    variables: { input: { isoCglCode: cgl || "" } },
    skip: !cgl,
  });
  const { marketingPlanTemplates } = verticalByCGL || {};

  const validMarketingPlanTemplates =
    marketingPlanTemplates?.filter(
      (template) => !opportunities.some((opportunity) => isEqual(opportunity.selectedLinesOfBusiness, template.lobs))
    ) || [];

  return (
    <CollapsibleList defaultOpen={false}>
      <CollapsibleListTrigger>
        <div className="flex">
          <span className="flex-auto font-semibold text-2xs text-muted-foreground uppercase">
            Create Marketing Plans
          </span>
          <span className="text-2xs">{validMarketingPlanTemplates.length}</span>
        </div>
      </CollapsibleListTrigger>
      <CollapsibleListContent>
        <Button
          onClick={() => {
            void createOpportunity({
              variables: {
                input: {
                  insuredId: insuredId as UUIDScalar,
                  desiredEffectiveDate: new Date(),
                },
              },
              onCompleted: (data) => {
                navigate(`/insured/${insuredId}/${data.createOpportunity.id}`);
              },
              refetchQueries: ["InsuredOpportunities"],
            });
          }}
        >
          Create Custom Marketing Plan
          <Icon icon="add" />
        </Button>
        {validMarketingPlanTemplates.map((template) => (
          <Button
            variant="outline"
            className="flex w-full justify-between rounded-none py-0 h-auto"
            key={template.id}
            onClick={() => {
              void createOpportunity({
                variables: {
                  input: {
                    insuredId: insuredId as UUIDScalar,
                    desiredEffectiveDate: opportunities[0]?.desiredEffectiveDate ?? new Date(),
                    selectedLinesOfBusiness: template.lobs,
                  },
                },
                refetchQueries: ["InsuredOpportunities"],
              });
            }}
          >
            <div className="text-sm py-4">
              {template.lobs.map((lob) => (
                <div key={lob} className="text-left">
                  {lob}
                </div>
              ))}
            </div>
            <Icon icon="add" />
          </Button>
        ))}
      </CollapsibleListContent>
    </CollapsibleList>
  );
};
