import { getAuthRequestHeaders } from "@cp/auth";
import {
  FileUploadFragment,
  File_Audience,
  useDeleteFileUploadMutation,
  useFindFileUploadQuery,
  useUpdateFileUploadAudienceMutation,
  useUpdateFileUploadFilenameMutation,
} from "../../../generated/graphql";
import { Button } from "../../components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu";

import { useEffect, useState } from "react";
import { z } from "zod";
import { ErrorMessage } from "../../components/error-message";
import { HasInternalRole } from "../../components/has-role";
import { Dialog, DialogContent, DialogDescription, DialogTitle, DialogTrigger } from "../../components/ui/dialog";
import { Icon } from "../../components/ui/icon";
import { Loading } from "../../components/ui/loading";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../components/ui/select";
import { useToast } from "../../components/ui/use-toast";
import { Input } from "../../forms/components/FormFields";
import { Reform } from "../../forms/Reform";
import { PDFViewer } from "../components/file-upload/file-upload-viewer";
import TagSelector from "./tag-selector";

export const FileMenu = ({ file }: { file: FileUploadFragment & { labels: string[] } }) => {
  const [selectedItem, setSelectedItem] = useState<MenuItemKey | null>(null);

  const handleMenuItemClick = (item: MenuItemKey) => {
    setSelectedItem(item);
  };

  type MenuItemKey = keyof typeof MENU_MAP;

  const MENU_MAP = {
    editTags: {
      menuItem: (
        <HasInternalRole>
          <DropdownMenuItem className="gap-3" onClick={() => handleMenuItemClick("editTags")}>
            <Icon icon="style" />
            Edit Tags
          </DropdownMenuItem>
        </HasInternalRole>
      ),
      content: <EditTagsDialogContent file={file} />,
    },
    editPermissions: {
      menuItem: (
        <HasInternalRole>
          <DropdownMenuItem className="gap-3" onClick={() => handleMenuItemClick("editPermissions")}>
            <Icon icon="lock" />
            Edit Permissions
          </DropdownMenuItem>
        </HasInternalRole>
      ),
      content: <EditFilePermissions file={file} />,
    },
    renameFile: {
      menuItem: (
        <HasInternalRole>
          <DropdownMenuItem className="gap-3" onClick={() => handleMenuItemClick("renameFile")}>
            <Icon icon="edit" />
            Edit Filename
          </DropdownMenuItem>
        </HasInternalRole>
      ),
      content: <RenameFile fileId={file.id} />,
    },
    deleteFile: {
      menuItem: (
        <HasInternalRole>
          <DropdownMenuItem className="gap-3" onClick={() => handleMenuItemClick("deleteFile")}>
            <Icon icon="delete" />
            Delete File
          </DropdownMenuItem>
        </HasInternalRole>
      ),
      content: <DeleteFile file={file} />,
    },
  };

  return (
    <Dialog>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button size="icon" variant="ghost">
            <Icon icon="more_vert" className="font-bold" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DialogTrigger>
            {Object.values(MENU_MAP).map((item, i) => (
              <div key={i}>{item.menuItem}</div>
            ))}
          </DialogTrigger>
          <DropdownMenuItem>
            <a
              href={`/api/files/${file.id}`}
              download={file.filename}
              media={file.mimeType}
              className="flex gap-3 items-center"
              onClick={async (e) => {
                e.preventDefault();
                // Not the best since it loads the entire file into memory before giving the user feedback.
                // But good enough for now
                const blob = await fetch(`/api/files/${file.id}`, {
                  headers: getAuthRequestHeaders(),
                })
                  .then((r) => r.blob())
                  // Create a new blob with the mime type
                  .then((b) => b.slice(0, b.size, file.mimeType));

                const url = URL.createObjectURL(blob);
                const anchor = document.createElement("a");
                anchor.href = url;
                anchor.download = file.filename;

                document.body.append(anchor);
                anchor.click();

                URL.revokeObjectURL(url);
                anchor.remove();
              }}
            >
              <Icon icon="download" />
              Download
            </a>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      {selectedItem && MENU_MAP[selectedItem] ? MENU_MAP[selectedItem].content : null}
    </Dialog>
  );
};

export function EditTagsDialogContent({ file }: { file: FileUploadFragment & { labels: string[] } }) {
  return (
    <DialogContent className="sm:max-w-[550px] max-h-[90vh] overflow-scroll">
      <DialogTitle>Edit Tags</DialogTitle>
      <DialogDescription className="text-wrap">
        Add or remove tags for this file.
        <div>{file.filename}</div>
      </DialogDescription>
      <div>
        <TagSelector fileId={file.id} />
      </div>
    </DialogContent>
  );
}
export function EditFilePermissions({ file }: { file: FileUploadFragment & { labels: string[] } }) {
  const { toast } = useToast();
  const [update] = useUpdateFileUploadAudienceMutation();

  return (
    <DialogContent className="sm:max-w-[550px]">
      <DialogTitle>Edit Permissions</DialogTitle>
      <DialogDescription className="text-wrap">
        Select who should have access to this file
        <div>{file.filename}</div>
      </DialogDescription>
      <div>
        <Select
          onValueChange={(value) => {
            void update({
              variables: {
                input: {
                  id: file.id,
                  audience: value as File_Audience,
                },
              },
              onError: (e) => {
                toast({ title: e.message });
              },
              onCompleted: () => {
                toast({ title: `Permission Updated` });
              },
              refetchQueries: ["FindFilesByLabel", "SubmissionPacket"],
            });
          }}
        >
          <SelectTrigger>
            <SelectValue placeholder="Audience" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={File_Audience.Internal}>Internal Users Only</SelectItem>
            <SelectItem value={File_Audience.External}>All Users</SelectItem>
          </SelectContent>
        </Select>
      </div>
    </DialogContent>
  );
}

export function DeleteFile({ file }: { file: FileUploadFragment & { labels: string[]; deletedAt?: Date | null } }) {
  const { toast } = useToast();
  const [deleteFile] = useDeleteFileUploadMutation();
  const restoreFile = file.deletedAt ? false : true;
  return (
    <DialogContent className="sm:max-w-[550px]">
      <DialogTitle>{restoreFile ? "Delete File" : "Restore File"}</DialogTitle>
      <DialogDescription className="text-wrap">
        {restoreFile ? "Delete File" : "Restore File"}: {file.filename}
        <div>This will {restoreFile ? "Delete The File" : "Restore the File"}.</div>
      </DialogDescription>
      <Button
        className="w-full"
        onClick={() => {
          void deleteFile({
            variables: {
              input: {
                id: file.id,
              },
            },
            onError: (e) => {
              toast({ title: e.message });
            },
            onCompleted: () => {
              toast({ title: `File ${restoreFile ? "Deleted" : "Restored"}` });
            },
            refetchQueries: ["FindFilesByLabel", "SubmissionPacket"],
          });
        }}
      >
        {restoreFile ? "Delete File" : "Restore File"}
      </Button>
    </DialogContent>
  );
}
export function RenameFile({ fileId }: { fileId: string }) {
  const { data, loading, error } = useFindFileUploadQuery({
    variables: {
      input: {
        id: fileId,
      },
    },
  });

  const file = data?.findFile;

  if (loading) {
    return <Loading />;
  }
  if (!file || error) {
    return <ErrorMessage />;
  }
  return (
    <DialogContent className="sm:max-w-[550px]">
      <DialogTitle>Rename file</DialogTitle>
      <DialogDescription className="text-wrap">
        Rename the selected file: {file.filename}
        <div>A file extension (.pdf, .png, etc) is not necessary</div>
      </DialogDescription>
      <RenameFileContent fileId={file.id} />
    </DialogContent>
  );
}

export const RenameFileContent = ({ fileId }: { fileId: string }) => {
  const [update] = useUpdateFileUploadFilenameMutation();
  const { toast } = useToast();
  const { data, loading } = useFindFileUploadQuery({
    variables: {
      input: {
        id: fileId,
      },
    },
  });
  const FormSchema = z.object({
    filename: z.string().min(1, { message: "Invalid name" }),
  });
  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      <Reform
        className="py-4 space-y-4"
        schema={FormSchema}
        onSubmit={(_, values) => {
          void update({
            variables: {
              input: {
                id: fileId,
                ...values,
              },
            },
            onError: (e) => {
              toast({ title: e.message });
            },
            onCompleted: () => {
              toast({ title: `Filename updated` });
            },
            refetchQueries: ["FindFilesByLabel", "FilesByLabel"],
          });
        }}
        defaultValues={{
          filename: data?.findFile.filename ?? "",
        }}
      >
        <Input name="filename" placeholder="New File Name" />
        <Button type="submit" className="w-full">
          {loading ? <Loading /> : "Save File Name"}
        </Button>
      </Reform>
    </div>
  );
};
export function ViewPDF({
  file,
  files,
  setSelectedFile,
}: {
  file: FileUploadFragment;
  files: Array<{
    __typename?: "FileUpload" | undefined;
    deletedAt?: any;
    createdAt: any;
    labels: string[];
    id: string;
    filename: string;
    mimeType: string;
    uploader?:
      | {
          __typename?: "UserAccount" | undefined;
          firstName: string;
          lastName: string;
        }
      | null
      | undefined;
  }>;
  setSelectedFile: (file: any) => void;
}) {
  const [index, setIndex] = useState<number>();

  useEffect(() => {
    const index = files.map((f) => f.id).indexOf(file.id);
    setIndex(index);
  }, [file]);

  const getNextFile = () => {
    if (!index && index !== 0) {
      return;
    }
    setSelectedFile(files[index + 1]);
  };
  const getPreviousFile = () => {
    if (!index) {
      return;
    }
    setSelectedFile(files[index - 1]);
  };

  return (
    <DialogContent
      className="flex justify-center items-center"
      style={{
        minWidth: "80vw",
        minHeight: "80vh",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Button onClick={getPreviousFile} disabled={index === 0}>
        <Icon icon="chevron_left" />
      </Button>
      <PDFViewer file={file} />
      <Button onClick={getNextFile} disabled={index === files.length - 1}>
        <Icon icon="chevron_right" />
      </Button>
    </DialogContent>
  );
}
