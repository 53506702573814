import { useParams } from "react-router";
import { LocationEditor } from "../../../components/location-editor";
import { RequirementElementProps } from "../requirement-element-props";

export default function BuildingElement({
  clientDataKey,
  onSubmissionRequirementsMet,
  onSubmissionRequirementsNotMet,
}: RequirementElementProps) {
  const { insuredId } = useParams();

  if (!insuredId) {
    return null;
  }

  return (
    <div className="col-span-full -m-4">
      <LocationEditor
        insuredId={insuredId}
        clientDataKey={clientDataKey}
        onSubmissionRequirementsMet={onSubmissionRequirementsMet}
        onSubmissionRequirementsNotMet={onSubmissionRequirementsNotMet}
      />
    </div>
  );
}
