import { RouteObject } from "react-router";

import { HasInternalRole } from "@/components/has-role";
import { Data } from "@/insured/marketing-plan/data";
import { ErrorPage } from "../error";
import { opportunityDetailsAction } from "./actions";
import { Coverage } from "./coverage";
import { Details } from "./details/details";
import { EscapeHatch } from "./escape-hatch";
import { Files } from "./files/files";
import { TagsCard } from "./files/tags-card";
import { History } from "./history";
import { InsuredRedirect } from "./insured-redirect";
import { Markets } from "./markets/markets";
import OpportunityNotes from "./notes";
import { OpportunityRoot } from "./opportunity-root";

export const opportunityRoutes = ({ path }: { path: string }): RouteObject[] => [
  {
    path: ":opportunityId",
    element: (
      <HasInternalRole elseShow={<InsuredRedirect />}>
        <OpportunityRoot path={path} />
      </HasInternalRole>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        action: opportunityDetailsAction,
        element: <Details />,
      },
      {
        path: "coverage",
        element: <Coverage />,
      },
      {
        path: "markets",
        element: <Markets />,
      },
      {
        path: "escape-hatch",
        element: <EscapeHatch />,
      },
      {
        path: "files",
        element: <Files />,
      },
      {
        path: "history",
        element: <History />,
      },
      {
        path: "notes",
        element: <OpportunityNotes />,
      },
      {
        path: "data",
        element: <Data />,
      },
      {
        path: "tags",
        element: <TagsCard />,
      },
    ],
  },
];

// export const marketingPlanRoutes = (): RouteObject[] => [
//   {
//     path: ":marketingPlanId",
//     element: <MarketingPlan />,
//     errorElement: <ErrorPage />,
//   },
// ];
