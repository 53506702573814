import { Bar } from "@/components/ui/bar";
import { Button } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { Input } from "@/components/ui/input";
import { PaneGroup } from "@/components/ui/pane";
import { useUpsertSearchParams } from "@/hooks/useUpsertSearchParams";
import { adaptSearchParams } from "../components/adapt-search-params";
import { KanbanColumns } from "./kanban-columns";
import { KanbanToolBar } from "./kanban-toolbar";

export const Kanban = () => {
  const [searchParams, upsertSearchParams, , removeSeachParams] = useUpsertSearchParams();
  const { term } = adaptSearchParams(searchParams);

  return (
    <PaneGroup>
      <Bar as="header" position="sticky" className="pr-2.5">
        {term ? (
          <Button
            variant="secondary"
            size="icon-sm"
            className="bg-accent rounded-full text-muted-foreground z-1"
            onClick={() => removeSeachParams(["term"])}
          >
            <Icon icon="close" />
          </Button>
        ) : (
          <Icon icon="search" className="bg-accent h-6 rounded-full text-muted-foreground text-xs w-6" />
        )}
        <Input
          placeholder="Search"
          value={term || ""}
          onChange={(event) => upsertSearchParams({ term: event.currentTarget.value })}
          className="bg-transparent border-0 px-14 rounded-none text-sm absolute top-0 left-0 w-full h-full z-0"
        />
        <KanbanToolBar />
      </Bar>
      <KanbanColumns />
    </PaneGroup>
  );
};
