import { useAtom } from "jotai";
import { Link, useSearchParams } from "react-router-dom";

import { defaultSearchAtom } from "@/atoms";
import { adaptSearchParams } from "@/components/adapt-search-params";
import { Badge } from "@/components/ui/badge";
import { Bar } from "@/components/ui/bar";
import { Button } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { Spinner } from "@/components/ui/loading";
import { Pane, PaneGroup } from "@/components/ui/pane";
import { cn } from "@/utils";
import { OpportunityGroupBy, useGroupedOpportunityListQuery } from "src/generated/graphql";
import { SelectBroker } from "./components/select-broker";

const defaultPresets = (brokerId?: string) => {
  const broker = !!brokerId && brokerId !== "null";

  return [
    {
      label: "Active",
      params: { ...(broker && { brokerId }) },
      showCount: broker,
    },
    {
      label: "Upcoming Eff. Date",
      params: { ...(broker && { brokerId }), groupBy: OpportunityGroupBy.EffectiveDate },
      showCount: broker,
    },

    {
      label: "Recently Created",
      params: {
        ...(broker && { brokerId }),
        dateRange: "72",
        groupBy: OpportunityGroupBy.CreatedAt,
      },
      showCount: broker,
    },
    {
      label: "Unassigned",
      params: {
        brokerId: "null",
      },
      showCount: true,
    },
  ];
};

export const HubPresets = () => {
  const [searchParams] = useSearchParams();
  const brokerId = searchParams.get("brokerId") || undefined;
  const searchParamsString = searchParams.toString();

  return (
    <PaneGroup>
      <Bar as="header">
        <SelectBroker />
      </Bar>
      <Pane className="flex flex-col gap-0 p-0">
        {defaultPresets(brokerId).map((preset) => (
          <SearchPresetLink
            key={preset.label}
            label={preset.label}
            searchParams={searchParamsString}
            params={new URLSearchParams(preset.params)}
            showCount={preset.showCount}
          />
        ))}
      </Pane>
    </PaneGroup>
  );
};

const SearchPresetLink = ({
  label,
  searchParams,
  params,
  showCount = true,
  handleClick,
}: {
  label: string;
  searchParams: string;
  params: URLSearchParams;
  showCount?: boolean;
  handleClick?: () => void;
}) => {
  const paramString = params.toString();
  const selected = paramString === searchParams;

  return (
    <Link
      to={`/opportunity?${paramString}`}
      onClick={handleClick}
      className={cn(
        "bg-background border-b flex gap-2 h-10 items-center px-3 shadow-unselected text-left transition-shadows text-xs",
        selected && "font-semibold shadow-selected"
      )}
    >
      <SetDefaultSearch paramString={paramString} selected={selected} />
      <span className="flex-auto truncate">{label}</span>
      {showCount && <SearchCount params={params} />}
    </Link>
  );
};

const SetDefaultSearch = ({ paramString, selected }: { paramString: string; selected: boolean }) => {
  const [defaultSearch, setDefaultSearch] = useAtom(defaultSearchAtom);
  return (
    <Button variant="ghost" size="icon-xs" onClick={() => setDefaultSearch(paramString)}>
      <Icon
        icon="bookmark"
        className={cn(
          "text-muted-foreground/50",
          paramString === defaultSearch && "filled",
          selected && "text-primary"
        )}
      />
    </Button>
  );
};

const SearchCount = ({ params }: { params: URLSearchParams }) => {
  const { page, groupBy, ...filterArgs } = adaptSearchParams(params);

  const { data: { groupedOpportunities } = {}, loading } = useGroupedOpportunityListQuery({
    variables: {
      args: {
        filter: {
          ...filterArgs,
          isTest: false,
        },
        groupBy: groupBy ?? OpportunityGroupBy.UpdatedAt,
      },
    },
    fetchPolicy: "cache-and-network",
  });

  const count = groupedOpportunities?.groups.flatMap((g) => g.opportunities).length;

  return (
    <Badge variant={count ? "default" : "secondary"} className="text-2xs">
      {loading ? <Spinner /> : count}
    </Badge>
  );
};
