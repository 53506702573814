import { QuoteFragment } from "../../../generated/graphql";
import { HasInternalRole } from "../../components/has-role";
import { QuoteFiles } from "./quote";
import { QuoteProposalForm } from "./quote-proposal-form";

const PendingQuote = ({ quote }: { quote: QuoteFragment; refetch?: () => void }) => {
  const { redactedQuoteId, processedQuoteId } = quote;

  return (
    <div className="space-y-3">
      <HasInternalRole>{redactedQuoteId && <QuoteProposalForm quote={quote} />}</HasInternalRole>

      {(redactedQuoteId || processedQuoteId) && <QuoteFiles quote={quote} />}
    </div>
  );
};

export default PendingQuote;
