import { cva, type VariantProps } from "class-variance-authority";
import { FC, HTMLAttributes } from "react";

import { cn } from "@/utils";

const paneGroupVariants = cva(
  "bg-muted flex flex-auto gap-px max-h-dvh max-w-full portrait:max-h-portrait landscape:max-w-landscape shadow-xl sticky top-0",
  {
    variants: {
      orientation: {
        vertical: "flex-col",
        horizontal: "",
      },
    },
    defaultVariants: {
      orientation: "vertical",
    },
  }
);

interface PaneGroupProps extends HTMLAttributes<HTMLElement>, VariantProps<typeof paneGroupVariants> {}

export const PaneGroup: FC<PaneGroupProps> = ({ children, className, orientation, id }) => (
  <div className={cn(paneGroupVariants({ orientation }), className)} id={id}>
    {children}
  </div>
);

const paneVariants = cva("flex-auto p-4", {
  variants: {
    layout: {
      default: "",
      columns: "flex max-lg:flex-col gap-4 md:items-start justify-center",
      list: "bg-accent flex flex-col p-0",
    },
    maxWidth: {
      none: "",
      narrow: "max-w-2xl mx-auto",
      wide: "max-w-6xl mx-auto",
    },
  },
  compoundVariants: [
    {
      layout: "columns",
      maxWidth: "wide",
      className: "gap-8",
    },
  ],
  defaultVariants: {
    layout: "default",
    maxWidth: "none",
  },
});

interface PaneProps extends HTMLAttributes<HTMLElement>, VariantProps<typeof paneVariants> {}

export const Pane: FC<PaneProps> = ({ children, className, layout, maxWidth, ...props }) => (
  <div className="bg-accent flex-auto overflow-y-auto" {...props}>
    <div className={cn(paneVariants({ layout, maxWidth }), className)}>{children}</div>
  </div>
);
