import { Label } from "@/components/ui/label";
import { TextArea } from "@/forms/components/FormFields";

export default function TextAreaElement({
  label,
  clientDataKey,
  ...props
}: {
  label: string;
  clientDataKey: string;
} & Parameters<typeof TextArea>) {
  return (
    <div className="col-span-full space-y-1">
      <Label className="text-sm">{label}</Label>
      <TextArea {...props} name={clientDataKey} required />
    </div>
  );
}
