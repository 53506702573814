import { AgentMessage } from "@/components/agent-message";
import { HasInternalRole } from "@/components/has-role";
import { Card } from "@/components/ui/card";
import { Activity } from "@/insured/activity/activity";
import { FollowUpActions } from "@/opportunity/components/actions/follow-up-actions";
import { useOpportunity } from "@/opportunity/loaders";
import { MarketingPlanDetails } from "./marketing-plan-details";

export const MarketingPlanRoot = () => {
  const { opportunity } = useOpportunity();

  if (!opportunity) {
    return null;
  }

  return (
    <>
      <AgentMessage state={opportunity.state} />
      <MarketingPlanDetails />
      <div className="flex gap-3 items-start">
        <div className="flex-auto space-y-3">
          <Activity opportunityId={opportunity.id} />
        </div>
        <HasInternalRole>
          <div className="flex-none space-y-3 w-80">
            <Card className="overflow-hidden">
              <FollowUpActions />
            </Card>
          </div>
        </HasInternalRole>
      </div>
    </>
  );
};
