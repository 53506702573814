import { FormEvent } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import { Loading } from "@/components/ui/loading";
import { useToast } from "@/components/ui/use-toast";
import { Input } from "@/forms/components/FormFields";
import { useOpportunity } from "@/opportunity/loaders";
import {
  AcordType,
  useClientDataQuery,
  useCreateClientDataMutation,
  useGenerateAcordMutation,
} from "../../../generated/graphql";

export const Data = () => {
  const formMethods = useForm();
  const { toast } = useToast();
  const { opportunity, loading } = useOpportunity();
  const { data: clientDataRes, loading: clientDataLoading } = useClientDataQuery({
    variables: { clientId: opportunity?.insured.id ?? "" },
    skip: !opportunity,
  });

  const [generateAcord, { loading: generatingAcord }] = useGenerateAcordMutation({
    onCompleted() {
      toast({ title: "Acord Generated" });
    },
  });

  const [createClientData] = useCreateClientDataMutation({
    onCompleted() {
      toast({ title: "Client Data saved" });
    },
    onError() {
      toast({ title: "Unable to save Client Data" });
    },
  });

  if (loading || !opportunity) {
    return <Loading />;
  }

  const handleEasyAcordUpdate = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const fd = formMethods.getValues();

    await createClientData({
      variables: {
        input: {
          insuredId: opportunity.insured.id,
          source: "EASY_ACORD_BROKER_UPDATE",
          clientDataInputs: Object.entries(fd)
            .filter(([keyAndIndex, value]) => {
              const [key, i] = keyAndIndex.split("::");
              const index = Number.isInteger(i) ? Number(i) : null;

              const cd = clientDataRes?.clientData.find((cd) => cd.key === key && cd.index === index);
              console.log(cd, value);
              return cd?.value !== value;
            })
            .map(([keyAndIndex, value]) => {
              const [key, index] = keyAndIndex.split("::");

              return {
                index: index ? Number(index) : undefined,
                key,
                value,
              };
            }),
        },
      },
    });
  };

  return (
    <>
      <div className="flex gap-2">
        <Button
          size="sm"
          disabled={generatingAcord}
          onClick={() =>
            void generateAcord({
              variables: { input: { opportunityId: opportunity.id, type: AcordType.Acord125 } },
            })
          }
        >
          Generate Acord 125
        </Button>
        <Button
          size="sm"
          disabled={generatingAcord}
          onClick={() =>
            void generateAcord({
              variables: { input: { opportunityId: opportunity.id, type: AcordType.Acord126 } },
            })
          }
        >
          Generate Acord 126
        </Button>
        <Button
          size="sm"
          disabled={generatingAcord}
          onClick={() =>
            void generateAcord({
              variables: { input: { opportunityId: opportunity.id, type: AcordType.Acord140 } },
            })
          }
        >
          Generate Acord 140
        </Button>
      </div>
      <Card>
        <CardHeader>
          <CardTitle>Easy Acord Data</CardTitle>
        </CardHeader>
        <CardContent>
          {clientDataLoading || !clientDataRes ? (
            <Loading />
          ) : (
            <FormProvider {...formMethods}>
              <form onSubmit={handleEasyAcordUpdate}>
                {clientDataRes.clientData
                  .filter((cd) => cd.source === "EASY_ACORD" || cd.source === "EASY_ACORD_BROKER_UPDATE")
                  .map((cd) => (
                    <div key={cd.id}>
                      <Label>{`${cd.key} ${cd.index ?? ""}`}</Label>
                      <Input
                        name={`${cd.key}${Number.isInteger(cd.index) ? `::${cd.index}` : ""}`}
                        defaultValue={cd.value}
                      />
                    </div>
                  ))}
                <Button className="mt-2">Save</Button>
              </form>
            </FormProvider>
          )}
        </CardContent>
      </Card>
    </>
  );
};
