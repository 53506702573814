import { Link } from "react-router-dom";

import { Icon } from "@/components/ui/icon";
import { InsuredDeepFragment } from "src/generated/graphql";

export const InsuredCard = ({ insured }: { insured: InsuredDeepFragment }) => {
  const { id: insuredId, name, opportunities, agent } = insured;
  return (
    <div key={insuredId} className="flex flex-col">
      <header className="group bg-background border border-b-0 flex gap-1 items-center justify-between px-4 pt-3 pb-5 rounded-t-lg text-xs">
        <span className="font-semibold truncate">{name}</span>
      </header>
      <div className="-mt-2">
        {opportunities.map(({ id: oppId, selectedLinesOfBusiness }) => (
          <Link
            key={oppId}
            to={`/insured/${insuredId}/${oppId}`}
            className="group bg-amber-50 border border-b-0 last:border-b border-amber-400 flex flex-col px-4 py-3 space-y-1.5 first:rounded-t-lg last:rounded-b-lg text-amber-800"
          >
            <div className="flex font-semibold gap-4 justify-between text-xs">
              <div className="space-y-1">
                {selectedLinesOfBusiness.map((lob) => (
                  <div key={lob}>{lob}</div>
                ))}
              </div>
              <Icon icon="arrow_forward" className="opacity-25 group-hover:opacity-100 transition-opacity" />
            </div>
            <div className="flex font-semibold gap-4 justify-between text-2xs">
              {agent.firstName} {agent.lastName}
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};
