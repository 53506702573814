import { Activity } from "@/insured/activity/activity";
import { useOpportunity } from "../loaders";
import { MarketingEfforts } from "./marketing-efforts/marketing-efforts";

export const Details: React.FC = () => {
  const { opportunity } = useOpportunity();

  if (!opportunity) {
    return null;
  }

  const { id, submissions } = opportunity;

  return (
    <>
      <MarketingEfforts opportunity={opportunity} submissions={submissions} />
      <Activity opportunityId={id} />
    </>
  );
};
