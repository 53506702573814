import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { useNavigate, useParams } from "react-router";
import { useBrokerAccountsQuery } from "src/generated/graphql";

export const NavigateBroker = ({ className, path }: { className?: string; path: string }) => {
  const navigate = useNavigate();
  const { brokerId } = useParams();
  const { data } = useBrokerAccountsQuery({ fetchPolicy: "cache-first" });
  const brokerAccounts = data?.userAccounts;

  const handleChange = (e: string) => {
    navigate(`${path}/${e}`);
  };

  return (
    <Select name="brokerId" value={brokerId ?? ""} onValueChange={(e) => handleChange(e)}>
      <SelectTrigger className={className}>
        <SelectValue placeholder="Select Broker" />
      </SelectTrigger>
      <SelectContent>
        <SelectItem value="">All</SelectItem>
        {brokerAccounts?.map((acc) => (
          <SelectItem key={acc.id} value={acc.id}>
            {acc.firstName} {acc.lastName}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};
