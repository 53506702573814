import { Label } from "@/components/ui/label";
import { Input } from "@/forms/components/FormFields";

export default function FreeFillWithMoney({
  clientDataKey,
  label,
  selectClientDataKey,
}: {
  clientDataKey: string;
  label: string;
  selectClientDataKey: string;
}) {
  return (
    <div className="col-span-1 space-y-1">
      <Label className="text-sm">{label}</Label>
      <div className="flex gap">
        <Input type="text" placeholder="Type" name={selectClientDataKey} required />

        <Input type="text" placeholder="Value" name={clientDataKey} required />
      </div>
    </div>
  );
}
