import React, { useState } from "react";

import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Icon } from "@/components/ui/icon";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { useToast } from "@/components/ui/use-toast";
import {
  CarrierContactFragment,
  CarrierContactType,
  useAddCarrierContactToAppetiteProductMutation,
} from "../../../../generated/graphql";
import { CarrierContactForm } from "./carrier-contact-form";
import { CarrierContactSelector } from "./carrier-contact-selector";

interface Props {
  type: CarrierContactType;
  contacts: CarrierContactFragment[];
  appetiteProductId: string;
}

export const CreateCarrierContactButton: React.FC<Props> = ({ type, appetiteProductId }) => {
  const { toast } = useToast();
  const [open, setOpen] = React.useState(false);
  const [showForm, setShowForm] = useState(false);
  const [addCarrierContact] = useAddCarrierContactToAppetiteProductMutation();

  const handleCreateComplete = () => {
    setOpen(false);
    setShowForm(false);
  };

  const handleSelected = (selected: CarrierContactFragment | null) => {
    if (selected) {
      if (selected.id === "new") {
        setShowForm(true);
      } else {
        void addCarrierContact({
          variables: {
            input: {
              id: selected.id,
              appetiteProductId,
              type,
            },
          },
          onCompleted: () => {
            toast({ title: "Carrier Contact Added Successfully" });
            setOpen(false);
          },
          refetchQueries: ["OpportunityDetails"],
        });
      }
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <Tooltip>
        <TooltipTrigger>
          <DialogTrigger>
            <Button variant="ghost" size="icon" onClick={() => setOpen(true)}>
              <Icon icon="add" />
            </Button>
          </DialogTrigger>
        </TooltipTrigger>
        <TooltipContent>Create / Add Contact</TooltipContent>
      </Tooltip>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Create Carrier Contact</DialogTitle>
        </DialogHeader>

        {showForm ? (
          <CarrierContactForm appetiteProductId={appetiteProductId} type={type} onCompleted={handleCreateComplete} />
        ) : (
          <CarrierContactSelector selected={null} setSelected={handleSelected} />
        )}
      </DialogContent>
    </Dialog>
  );
};
