import { Link } from "react-router-dom";

import { Icon } from "@/components/ui/icon";
import { STATE_METADATA } from "@/metadata";
import { cn } from "@/utils";
import { BankanPoliciesQuery, PolicyState } from "../../generated/graphql";

type BankanInsured = BankanPoliciesQuery["bankanPolicies"]["groupedPolicies"][number];

export const PoliciesCard = ({ insured, highlightState }: { insured: BankanInsured; highlightState: PolicyState }) => {
  const { insuredId, insuredName, policies } = insured;
  return (
    <div>
      <header className="group bg-muted border border-b-0 flex gap-1 items-center justify-between px-4 pt-3 pb-5 rounded-t-lg text-xs">
        <span className="font-semibold truncate">{insuredName}</span>
      </header>
      <div className="bg-background -mt-2 rounded-lg">
        {policies.map((policy) => {
          const { id, appetiteProduct, policyNumber, state } = policy;
          const { cardClassName } = STATE_METADATA[state];
          return (
            <Link
              key={id}
              to={`/insured/${insuredId}/policies/${id}`}
              className={cn(
                "group border flex flex-col -mt-px px-4 py-3 space-y-1.5 first:rounded-t-lg last:rounded-b-lg",
                state === highlightState && `${cardClassName} relative z-1`
              )}
            >
              <div className="flex font-semibold gap-4 items-start justify-between text-xs truncate">
                <span className="truncate">
                  <span className="font-semibold">{appetiteProduct.carrierName}:</span>
                  <span>{appetiteProduct.carrierProductName}</span>
                </span>
                <Icon icon="arrow_forward" className="opacity-25 group-hover:opacity-100 transition-opacity" />
              </div>
              <div className="flex justify-between text-2xs">#{policyNumber}</div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};
